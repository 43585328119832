@use '../setting/index' as *;

.u-pc {
  display: none;
  @include mq-min(md) {
    display: block;
  }
}
.u-sp {
  display: block;
  @include mq-min(md) {
    display: none;
  }
}

.u-pc--inline-block {
  display: none;
  @include mq-min(md) {
    display: inline-block;
  }
}

.u-hidden {
  overflow: hidden;
}

.u-ml70 {
  @include mq-min(md) {
    margin-left: 70px;
  }
}

.u-mr60 {
  @include mq-min(md) {
    margin-right: 60px;
  }
}

.u-mr20 {
  @include mq-min(md) {
    margin-right: 20px;
  }
}
