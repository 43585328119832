@use '../setting/index' as *;

.p-company-section {
  position: relative;
  padding: 50px 0;
  @include mq-min(md) {
    padding: 100px 0;
  }
}

.p-company-section::after {
  content: '';
  position: absolute;
  top: -10px;
  right: -166px;
  width: 414px;
  height: 442px;
  background: url(../images/common/bg_palm_sp.png) no-repeat center / contain;
  z-index: -1;
  @include mq-min(md) {
    top: -150px;
    right: -594px;
    width: 1186px;
    height: 1265px;
    background: url(../images/common/bg_palm_pc.png) no-repeat center / contain;
  }
}


.p-company-section::before {
  content: '';
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 25px;
  width: min(500px, 66.66vw);
  background-color: #EBE6DE;
  mix-blend-mode: multiply;
  z-index: 6;
  transform: translateX(100%);
  opacity: 0;
  transition: transform .6s cubic-bezier(0.565, 0.43, 0.24, 0.92), opacity .6s;
  @include mq-min(md) {
    top: 100px;
    right: -5px;
    width: 774px;
    height: 721px;
  }
}

.is-show.p-company-section::before {
  transform: translateX(0);
  opacity: 1;
}


.p-company-description {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
  color: #333;
  padding-top: 12px;
  transform: translateY(60px);
  transition: transform .7s .5s, opacity .7s .5s;
  opacity: 0;

  @include mq-min(md) {
    font-size: 1.8rem;
    margin-top: 16px;
  }
}

.is-show .p-company-description {
  transform: translateY(0);
  opacity: 1;
}


.p-company-description dt {
  position: relative;
  width: 95px;
  padding: 15px 0 12px 15px;
  border-bottom: 1px solid #000;
  font-weight: 400;
  z-index: 7;

  @include mq-min(md) {
    width: 238px;
    padding: 41px 0 32px 20px;
  }
}
.p-company-description dd {
  position: relative;
  width: calc(100% - 95px);
  padding: 15px 0 12px 15px;
  border-bottom: 1px solid #000;
  z-index: 7;
  @include mq-min(md) {
    width: calc(100% - 238px);
    padding: 41px 0 32px 20px;
  }
}

.p-company-description__dd-flex {
  display: flex;
  flex-wrap: wrap;
}

.p-company-description__dd-flex span {
  &:nth-child(2) {
    width: calc(100% - 72px);
    padding-left: 1em;
    @include mq-min(md) {
      width: calc(100% - 106px);
    }
  }
  &:nth-child(3) {
    margin-top: 10px;
    @include mq-min(md) {
      margin-top: 22px;
    }
  }
  &:nth-child(4) {
    width: 100%;
    margin-top: 10px;
    @include mq-min(md) {
      width: calc(100% - 106px);
      padding-left: 1em;
      margin-top: 22px;
    }
  }
}

.p-company-description__dd-slash {
  position: relative;
  width: 72px;
  @include mq-min(md) {
    width: 106px;
  }
}

.p-company-description__dd-slash::before {
  content: '/';
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.2rem;
  @include mq-min(md) {
    font-size: 1.8rem;
    right: -2px;
  }
}
