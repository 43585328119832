@use '../setting/index' as *;

.p-access-section {
  position: relative;
  padding: 80px 0 60px;
  @include mq-min(md) {
    padding: 200px 0 100px;
  }
}

.p-access-section::before {
  content: '';
  position: absolute;
  top: 50px;
  left: 0;
  width: min(500px, 66.66vw);
  height: min(982px, 130.933vw);
  background-color: #DBD6CC;
  mix-blend-mode: multiply;
  @include mq-min(md) {
    top: 100px;
    width: 774px;
    height: 750px;
  }
}

.p-access-media {
  @include mq-min(md) {
    display: flex;
  }
}

.p-access-media__image {
  position: relative;
  @include mq-min(md) {
    width: 66.667%;
  }
}

.p-access-media__image::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 100%;
  background-color: #DBD6CC;
  display: block;
  mix-blend-mode: multiply;
  z-index: -1;
}

.p-access-media__image picture {
  display: block;
}

.p-access-media__image img {
  border: 1px solid #FFF;
  @include mq-min(md) {
    border-width: 3px;
  }
}

.p-access-media__text-area {
  margin-top: 40px;
  @include mq-min(md) {
    margin-top: 0;
    margin-left: 58px;
  }
}

.p-access-map {
  padding: 30px 30px 50px;
  @include mq-min(md) {
    max-width: 1600px;
    padding: 100px 40px 103px;
    margin: auto;
  }
}

.p-access-media__list {
  width: 186px;
  margin-top: 30px;
  @include mq-min(md) {
    width: 234px;
    margin-top: 40px;
  }
}

.p-access-media__list-item {
  font-size: 1.2rem;
  line-height: 2;
  border-bottom: 1px dotted $border_color;
  @include mq-min(md) {
    font-size: 1.6rem;
  }
}
.p-access-media__list-item:first-child {
  @include mq-min(md) {
    padding-bottom: 40px;
  }
}
.p-access-map__inner {
  position: relative;
  padding-top: 79.365%;
  @include mq-min(md) {
    padding-top: 46.0526%;
  }
}

.p-access-map__inner iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.p-access-section--bg-image {
  background: url(../images/access/section_bg_sp.jpg) repeat-y center / contain;
  padding: 30px 0 50px;
  @include mq-min(md) {
    background: url(../images/access/section_bg_pc.jpg) repeat-y center / contain;
    padding: 97px 0 100px;
  }
}

.p-access-flow {
  margin-top: 100px;
}

.p-access-flow__heading {
  position: relative;
  width: fit-content;
  font-size: 1.2rem;
  @include ten-mincho;
  text-align: center;
  margin: 0 auto 30px;
  @include mq-min(md) {
    font-size: 1.8rem;
    text-align: left;
    margin: 0 0 60px;
  }
}

.p-access-flow__heading::before {
  content: '';
  position: absolute;
  top: 57%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: $border_color;

  @include mq-min(md) {
    top: 62%;
  }
}

.p-access-flow__heading-en {
  display: block;
  font-size: 2rem;
  @include trajan-normal;
  @include mq-min(md) {
    font-size: 4rem;
  }
}

.p-access-flow--car {
  margin-top: 50px;
}

.p-access-flow-pc-flex {
  display: contents;
  @include mq-min(md) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    overflow: hidden;
  }
}

%flow-box {
  display: grid;
  place-content: center;
  height: 45px;
  font-size: 1.3rem;
  text-align: center;
  border: 1px solid $border_color;
  background-color: #fff;
  @include mq-min(md) {
    position: relative;
    height: 130px;
    font-size: 1.8rem;
    z-index: 2;
    text-align: center;
  }  
}

.p-access-flow__box {
  @extend %flow-box;
  @include mq-min(md) {
    width: 200px;
  }
}

.p-access-flow__box span {
  @include mq-min(md) {
    font-size: 1.4rem;
    letter-spacing: 0.05em
  }
}

.p-access-flow__box--middle {
  @extend %flow-box;
  @include mq-min(md) {
    width: 170px;
  }
}

.p-access-flow__box--end {
  @extend %flow-box;
  color: #fff;
  background-color: $accent_color;
  @include mq-min(md) {
    width: 300px;
  }
}

%flow-text-icon {
  position: relative;
  font-size: 1.3rem;
  text-align: center;
  height: 98px;
  @include mq-min(md) {
    font-size: 1.8rem;
    height: auto;
  }
}
%flow-text-span {
  font-size: 3rem;
  @include mq-min(md) {
    font-size: 4rem;
  }
}
%flow-text-before {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 5px;
  height: 98px;
  border-left: 1px solid $border_color;
  border-right: 1px solid $border_color;
  background-color: #DBD6CC;
  @include mq-min(md) {
    width: 100vw;
    height: 10px;
    left: -40vw;
    top: 59px;
    border-left: none;
    border-right: none;
    border-top: 1px solid $border_color;
    border-bottom: 1px solid $border_color;
    z-index: 1;
  }
}
%flow-text-after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.p-access-flow__text-icon-car {
  @extend %flow-text-icon;
  padding-top: 26px;
  @include mq-min(md) {
    padding-top: 64px;
  }
}
.p-access-flow__text-icon-car span {
  @extend %flow-text-span;
}
.p-access-flow__text-icon-car::before {
  @extend %flow-text-before;
}
.p-access-flow__text-icon-car::after {
  @extend %flow-text-after;
  top: 20px;
  width: 50px;
  height: 22px;
  background-image: url(../images/icon/icon_car.svg);
  @include mq-min(md) {
    top: 28px;
  }
}

.p-access-flow__text-icon-bus {
  @extend %flow-text-icon;
  padding-top: 40px;
  @include mq-min(md) {
    padding-top: 64px;
  }
}
.p-access-flow__text-icon-bus span {
  @extend %flow-text-span;
}
.p-access-flow__text-icon-bus::before {
  @extend %flow-text-before;
}
.p-access-flow__text-icon-bus::after {
  @extend %flow-text-after;
  top: 20px;
  width: 47px;
  height: 30px;
  background-image: url(../images/icon/icon_bus.svg);
  @include mq-min(md) {
    top: 24px;
  }
}

.p-access-flow__text-icon-human {
  @extend %flow-text-icon;
  padding-top: 40px;
  @include mq-min(md) {
    padding-top: 64px;
  }
}
.p-access-flow__text-icon-human span {
  @extend %flow-text-span;
}
.p-access-flow__text-icon-human::before {
  @extend %flow-text-before;
}
.p-access-flow__text-icon-human::after {
  @extend %flow-text-after;
  top: 14px;
  left: 48%;
  width: 37px;
  height: 39px;
  background-image: url(../images/icon/icon_human.svg);
  @include mq-min(md) {
    top: 12px;
  }
}

.p-access-flow__text-icon-train {
  @extend %flow-text-icon;
  padding-top: 45px;
  padding-left: 64px;
  line-height: 0.5;
  @include mq-min(md) {
    padding-top: 80px;
    padding-left: 0;
    line-height: 0.9;
  }
}
.p-access-flow__text-icon-train span {
  @extend %flow-text-span;
}
.p-access-flow__text-icon-train::before {
  @extend %flow-text-before;
}
.p-access-flow__text-icon-train::after {
  @extend %flow-text-after;
  top: 20px;
  left: 38%;
  width: 45px;
  height: 30px;
  background-image: url(../images/icon/icon_train.svg);
  @include mq-min(md) {
    top: 23px;
    left: 50%;
  }
}

%p-access-flow__explanation {
  font-size: 1.3rem;
  margin-top: 20px;
  @include mq-min(md) {
    font-size: 1.8rem; 
  }
}
.p-access-flow__explanation {
  @extend %p-access-flow__explanation;
}

.p-access-flow__explanation--indent {
  @extend %p-access-flow__explanation;
  padding-left: 1em;
  text-indent: -1em;
}

.p-access-flow__explanation-square {
  width: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $border_color;
  margin-right: 10px;
  @include mq-min(md) {
    width: 70px;
  }
}

.p-access-flow__explanation-slash {
  display: none;
  @include mq-min(md) {
    display: inline-block;
  }
}

// 背景画像
.p-access-bg-palm {
  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: -100px;
    width: 350px;
    height: 275px;
    background: url(../images/common/bg_palm_sp.png) no-repeat center / cover;
    mix-blend-mode: multiply;
    transform: scale(-1, 1);
    @include mq-min(md) {
      top: 60px;
      left: -850px;
      bottom: initial;
      width: 1105px;
      height: 788px;
      background: url(../images/common/bg_palm_pc.png) no-repeat center / cover;
    }
  }
}

.p-access-bg-monstera {
  &::after {
    content: "";
    position: absolute;
    bottom: -50px;
    right: -60px;
    width: 190px;
    height: 224px;
    background: url(../images/common/bg_monstera_sp.png) no-repeat center / cover;
    mix-blend-mode: multiply;
    z-index: 1;
    @include mq-min(md) {
      bottom: -370px;
      right: -370px;
      width: 600px;
      height: 704px;
      background: url(../images/common/bg_monstera_pc.png) no-repeat center / cover;
      mix-blend-mode: multiply;
      z-index: 1;
    }
  }
}