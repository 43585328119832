@use '../setting/index' as *;

.l-header {
  background-color: $accent_color;
  z-index: 20;
  @include mq-min(md) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 28px 0;
  }
  @include mq-min(xl) {
    padding: 51px 0 49px;
  }
}

.l-header__inner {
  @include mq-min(md) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
    max-width: 1600px;
    padding: 0 130px 0 30px;
    margin: auto;
  }
  @include mq-min(lg) {
    padding: 0 235px 0 40px;
  }
}

.l-header__logo {
  position: relative;
  display: block;
  padding: 13px 39px;
  background-color: $accent_color;
  z-index: 11;
  @include mq-min(md) {
    padding: 0;
    background: none;
  }
}

.l-header__logo picture {
  width: 297px;
  @include mq-min(md) {
    width: 100%;
  }
}

.l-header__nav-sp-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FAF7F2;
  box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.16);
  border-radius: 25px 25px 0 0;
  z-index: 10;
  @include mq-min(md) {
    display: contents;
  }
}

.l-header__nav {
  position: relative;
  z-index: 11;

  @include mq-min(md) {
    position: absolute;
    top: 0;
    left: initial;
    right: 40px;
    width: 160px;
    height: 160px;
    background: #D4CEC5;
    box-shadow: none;
    border-radius: 50%;
    border: 2px solid $border_color;
  }
}

.l-header__nav-menu {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 11;
  @include mq-min(md) {
    width: 100%;
    height: 100%;
  }
}

.l-header__nav-menu li {
  flex-grow: 1;
  position: relative;
  background-color: #FAF7F2;
  z-index: 11;
  @include mq-min(md) {
    width: 100%;
    height: 100%;
  }
}

.l-header__nav-menu li:first-child {
  border-radius: 25px 0 0 0;
  @include mq-min(md) {
    border-radius: 50%;
    box-shadow: 0px 0px transparent;
    transition: box-shadow ease .3s;
  }
}

.l-header__nav-menu li:first-child:hover {
  @include mq-min(md) {
    box-shadow: 6px 6px $border_color;
  }
}

.l-header__nav-menu li:last-child {
  border-radius: 0 25px 0 0;
  @include mq-min(md) {
    border-radius: 0;
  }
}

.l-header__nav-menu li:nth-of-type(2) {
  flex-grow: 1.2;
}
%l-header__nav-menu-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 1.2rem;
  height: 60px;
  padding-bottom: 7px;
}
.l-header__nav-menu-item {
  @extend %l-header__nav-menu-item;
  @include mq-min(md) {
    display: none;
  }
}

.l-header__nav-menu-item--tel {
  @extend %l-header__nav-menu-item;
  color: #fff;
  background-color: $accent_color;
  &::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 50%;
    transform: translateX(-50%);
    width: 22px;
    height: 24px;
    background: url(../images/icon/icon_tel_sp.svg) no-repeat center / cover;
  }
  @include mq-min(md) {
    display: none;
  }
}

.l-header__nav-menu-item--reserve {
  @include mq-min(md) {
    font-size: 1.8rem;
    @include ten-mincho;
    padding: 0 1em 30px;
    width: 100%;
    height: 100%;
    transition: transform .3s;
    will-change: transform;
  }

  @extend %l-header__nav-menu-item;
  &::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 50%;
    transform: translateX(-50%);
    width: 25px;
    height: 24px;
    background: url(../images/icon/icon_reserve.svg) no-repeat center / cover;
    @include mq-min(md) {
      top: 33px;
      width: 40px;
      height: 38px;
    }
  }
  &::after {
    @include mq-min(md) {
      content: "BOOK NOW";
      display: block;
      width: max-content;
      position: absolute;
      bottom: 57px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1.2rem;
      @include trajan-normal;
      border-bottom: 1px solid $border_color;
    }
  }
}

.l-header__nav-menu-item--reserve:hover {
  @include mq-min(md) {
    transform: translate(3px, 3px);
  }
}

.l-header__nav-menu-item span {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 1px;
  background-color: $border_color;
  @include mq-min(md) {
    display: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 0;
    width: 20px;
    height: 1px;
    background-color: $border_color;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 20px;
    height: 1px;
    background-color: $border_color;
  }
}

.l-header__menu {
  opacity: 0;
  position: absolute;
  top: -60px;
  right: 0;
  left: 0;
  bottom: 0;
  height: 102vh;
  background: rgba($color: #DBD6CC, $alpha: .9);
  padding: 188px 30px 108px;
  transform: translateY(100%);
  transition: transform .3s;
  z-index: 4;
  overflow: scroll;
  @include ten-mincho;
  z-index: -5;

  @include mq-min(md) {
    opacity: 1;
    position: static;
    height: auto;
    transform: none;
    background: none;
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 0;
    margin-left: 20px;
    overflow: auto;
    z-index: 3;
  }

  @include mq-min(xl) {
    column-gap: 38px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 152px;
    right: 32px;
    width: 20px;
    transform: rotate(45deg);
    border-top: 3px solid $border_color;
    @include mq-min(md) {
      content: none;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 152px;
    right: 32px;
    width: 20px;
    transform: rotate(-45deg);
    border-top: 3px solid $border_color;
    @include mq-min(md) {
      content: none;
    }
  }
}

.l-header__menu.is-show {
  opacity: 1;
  z-index: 3;
  transform: translateY(-90%);
  @include mq-min(md) {
    transform: none;
  }
}

.l-header__menu-item {
  position: relative;
  display: block;
  font-size: 1.5rem;
  padding: 19px 0;
  border-bottom: 1px dotted $border_color;
  @include mq-min(md) {
    color: #fff;
    font-size: 1.6rem;
    border: none;
    padding: 0;
  }

  &::before {
    @include mq-min(md) {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #fff;
      transition: transform .3s;
      transform: scale(0, 1);
      transform-origin: left top;
    }
  }

  &:hover {
    &::before {
      @include mq-min(md) {
        transform: scale(1, 1);
      }
    }
  }
}

.l-header__menu-item::after {
  content: "";
  position: absolute;
  top: 51%;
  right: 11px;
  transform: translateY(-50%) rotate(45deg);
  border-top: solid 1px $border_color;
  border-right: solid 1px $border_color;
  width: 8px;
  height: 8px;
  @include mq-min(md) {
    content: none;
  }
}