@use '../setting/index' as *;

.p-faq-section {
  position: relative;
  padding: 50px 0;
  @include mq-min(md) {
    padding: 200px 0 100px;
  }
}

.p-faq-anchor {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.p-faq-heading {
  position: relative;
  width: fit-content;
  font-size: 1.3rem;
  @include ten-mincho;
  margin-left: 15px;
  @include mq-min(md) {
    font-size: 1.8rem;
    margin-left: 0;
  }
}

.p-faq-heading::before {
  content: '';
  position: absolute;
  top: 57%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: $border_color;

  @include mq-min(md) {
    top: 58%;
  }
}

.p-faq-heading__en {
  display: block;
  font-size: 1.9rem;
  @include trajan-normal;
  @include mq-min(md) {
    font-size: 4rem;
    padding-bottom: 6px;
  }
}


.p-faq-anchor__item {
  width: calc((100% - 20px) / 2);
  @include mq-min(md) {
    width: 285px;
  }
}

.p-faq-anchor__item a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 45px;
  @include ten-mincho;
  font-size: 1.2rem;
  text-align: center;
  letter-spacing: 0;
  background-color: #EBE6DE;
  @include mq-min(md) {
    font-size: 1.6rem;
    min-height: 54px;
    letter-spacing: 0.1em;
    transition: opacity .3s;
  }
  &:hover {
    @include mq-min(md) {
      opacity: .5;
    }
  }
}

.p-faq-anchor__item a::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20px;
  background-color: #EBE6DE;
  mix-blend-mode: multiply;
  clip-path: polygon(50% 0%, 100% 50%, 52% 100%, 0 100%, 0 0);
  @include mq-min(md) {
    width: 32px;
  }
}

.p-faq-content {
  position: relative;
  margin-top: 94px;
  @include mq-min(md) {
    padding-top: 187px;
    margin-top: 0;
  }
}

.p-faq-accordion:first-of-type {
  margin-top: 18px;
  @include mq-min(md) {
    margin-top: 40px;
  }
}

.p-faq-accordion dt {
  position: relative;
  font-size: 1.2rem;
  @include ten-mincho;
  letter-spacing: 0.1em;
  padding: 10px 42px 10px 52px;
  border-bottom: 1px solid $border_color;
  cursor: pointer;
  @include mq-min(md) {
    font-size: 1.8rem;
    padding: 24px 60px 31px 98px;
  }
}

.p-faq-accordion dt::before {
  content: 'Q.';
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  font-size: 1.9rem;
  @include trajan-normal;
  @include mq-min(md) {
    left: 20px;
    font-size: 4rem;
  }
}

.p-faq-accordion dt span::before,
.p-faq-accordion dt span::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    height: 1px;
    width: 10px;
    background: $border_color;
    transition: all 0.6s;
    @include mq-min(md) {
      right: 30px;
      width: 16px;
      height: 2px;
    }
}
.p-faq-accordion dt span::before {
  transform: translateY(-50%) rotate(180deg);
}
.p-faq-accordion dt span::after {
  transform: translateY(-50%) rotate(90deg);
}
.p-faq-accordion dt.is-open span::before,
.p-faq-accordion dt.is-open span::after {
  transform: rotate(360deg);
}

.p-faq-accordion dd {
  display: none;
  position: relative;
  font-size: 1.2rem;
  color: #333;
  padding: 15px 15px 15px 52px;
  background-color: rgba($color: #DBD6CC, $alpha: .2);
  mix-blend-mode: multiply;
  @include mq-min(md) {
    font-size: 1.6rem;
    padding: 20px 15px 23px 98px;
  }
}

.p-faq-accordion dd::before {
  content: 'A.';
  position: absolute;
  top: 10px;
  left: 16px;
  font-size: 1.9rem;
  @include trajan-normal;
  color: #AA1414;
  @include mq-min(md) {
    left: 26px;
    font-size: 4rem;
  }
}

#stay.p-faq-content::before {
  content: '';
  position: absolute;
  top: -58px;
  right: -70px;
  width: 190px;
  height: 224px;
  background: url(../images/common/bg_monstera_sp.png) no-repeat center / contain;
  z-index: -1;
  @include mq-min(md) {
    top: -242px;
    right: -460px;
    width: 600px;
    height: 704px;
    background: url(../images/common/bg_monstera_pc.png) no-repeat center / contain;
  }
}

#stay.p-faq-content::after {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: 188px;
    left: -600px;
    width: 658px;
    height: 663px;
    background: url(../images/common/bg_hibiscus_pc.png) no-repeat center / contain;
    z-index: -1;
  }
}

#rooms.p-faq-content::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: -194px;
    right: -955px;
    width: 1186px;
    height: 1265px;
    background: url(../images/common/bg_palm_pc.png) no-repeat center / contain;
    z-index: -1;
  }
}

#rooms.p-faq-content::after {
  content: '';
  position: absolute;
  top: -56px;
  left: -124px;
  width: 230px;
  height: 232px;
  background: url(../images/common/bg_hibiscus_sp.png) no-repeat center / contain;
  z-index: -1;
  @include mq-min(md) {
    top: 500px;
    left: -900px;
    width: 1185px;
    height: 1380px;
    background: url(../images/common/bg_strelitzia_pc.png) no-repeat center / contain;
  }
}

#rental.p-faq-content::after {
  content: '';
  position: absolute;
  top: -140px;
  right: -220px;
  width: 414px;
  height: 442px;
  background: url(../images/common/bg_palm_sp.png) no-repeat center / contain;
  z-index: -1;
  @include mq-min(md) {
    content: none;
  }
}

#parking.p-faq-content::before {
  content: '';
  position: absolute;
  top: -100px;
  left: -200px;
  width: 397px;
  height: 436px;
  background: url(../images/common/bg_strelitzia_sp.png) no-repeat center / contain;
  z-index: -1;
  @include mq-min(md) {
    top: -30px;
    right: -470px;
    left: initial;
    width: 695px;
    height: 612px;
    background: url(../images/common/bg_orchid_pc.png) no-repeat center / contain;
  }
}

#sightseeing.p-faq-content::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: 270px;
    left: -500px;
    width: 600px;
    height: 704px;
    background: url(../images/common/bg_monstera_pc.png) no-repeat center / contain;
    transform: scale(-1, 1);
    z-index: -1;
  }
}

#others.p-faq-content::before {
  content: '';
  position: absolute;
  top: -100px;
  right: -120px;
  width: 250px;
  height: 221px;
  background: url(../images/common/bg_orchid_sp.png) no-repeat center / contain;
  z-index: -1;
  @include mq-min(md) {
    content: none;
  }
}