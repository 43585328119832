@use '../setting/index' as *;

.p-rooms-read {
  padding: 50px 0 40px;
  @include mq-min(lg) {
    padding: 100px 0;
  }
}

.p-rooms-read__heading {
  font-size: 1.7rem;
  @include ten-mincho;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid $border_color;
  @include mq-min(md) {
    font-size: 2.2rem;
  }
}

.p-rooms-read__list {
  font-size: 1.3rem;
  color: #333;
  margin-top: 20px;
  padding: 0 10px;
  @include mq-min(md) {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    width: 698px;
    font-size: 1.2rem;
    padding: 0;
    margin: 30px auto 0;
  }
}

.p-rooms-read__list li + li {
  margin-top: 4px;
  @include mq-min(md) {
    margin-top: 0;
  }
}

.p-rooms-read__list li:nth-child(odd) {
  @include mq-min(md) {
    margin-right: 1em;
  }
}

.p-rooms-read__text {
  font-size: 1.3rem;
  color: #333;
  margin-top: 20px;
  padding: 0 10px;
  @include mq-min(md) {
    width: 698px;
    font-size: 1.2rem;
    padding: 0;
    margin: 10px auto 0;
  }
}

.p-rooms-section--single {
  position: relative;
  padding: 80px 0 50px;
  @include mq-min(lg) {
    padding: 100px 0;
    scroll-margin-top: 160px;
  }
}

.p-rooms-section--single__inner {
  position: relative;
  width: fit-content;
  margin: auto;
}


.p-rooms-section--single__inner::before {
  content: '';
  position: absolute;
  top: -30px;
  left: 0;
  width: min(500px, 66.666vw);
  height: min(710px, 94.666vw);
  background-color: #DBD6CC;
  mix-blend-mode: multiply;
  transform: translateX(-100%);
  opacity: 0;
  transition: transform .6s cubic-bezier(0.565, 0.43, 0.24, 0.92), opacity .6s;
  @include mq-min(md) {
    top: -100px;
    left: -320px;
    width: 774px;
    height: 750px;
  }
}

.is-show.p-rooms-section--single__inner::before {
  transform: translateX(0);
  opacity: 1;
}

.p-rooms-section--single__inner::after {
  content: '';
  position: absolute;
  top: 0;
  left: -200px;
  width: min(700px, 93.333vw);
  height: min(550px, 73.333vw);
  background: url(../images/common/bg_palm_sp.png) no-repeat center / cover;
  transform: scale(-1, 1);
  mix-blend-mode: multiply;
  @include mq-min(md) {
    top: 100px;
    left: -750px;
    width: 1000px;
    height: 788px;
    background: url(../images/common/bg_palm_pc.png) no-repeat center / cover;
  }
}

.p-rooms-section--single__grid {
  position: relative;
  opacity: 0;
  transform: translateY(60px);
  transition: transform .7s .5s, opacity .7s .5s;
  @include mq-min(md) {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 180px 160px;
    grid-template-rows: repeat(3, 1fr) 100px;
    align-items: end;
  }
  @include mq-min(lg) {
    grid-template-columns: repeat(4, 1fr) 260px 140px;
    grid-template-rows: 180px 204px 157px 100px;
  }
}

.is-show .p-rooms-section--single__grid {
  transform: translateY(0);
  opacity: 1;
}

.p-rooms-section--single__grid .p-rooms-heading-set {
  @include mq-min(md) {
    grid-area: 1 / 1 / 2 / 7;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  @include mq-min(lg) {
    grid-area: 1 / 5 / 2 / 7;
    display: block;
    margin-left: 53px;
  }
}
.p-rooms-section--single__grid .p-rooms-gallery__main {
  @include mq-min(md) {
    grid-area: 2 / 1 / 4 / 5;
  }
  @include mq-min(lg) {
    grid-area: 1 / 1 / 4 / 5;
  }
}

.p-rooms-section--single__grid .p-rooms-gallery__sub {
  @include mq-min(md) {
    grid-area: 2 / 5 / 4 / 7;
    margin-left: 53px;
  }
  @include mq-min(lg) {
    grid-area: 2 / 5 / 4 / 7;
  }
}

.p-rooms-section--single__grid .p-rooms-button {
  @include mq-min(md) {
    grid-area: 4 / 3 / 5 / 5;
    margin-left: auto;
  }
  @include mq-min(lg) {
    grid-area: 4 / 3 / 5 / 5;
  }
}


.p-rooms-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 4px;
  font-size: 1.2rem;
  color: #333;
  margin-top: 20px;
  @include mq-min(md) {
    display: block;
    margin-top: 16px;
    margin-left: 20px;
    font-size: 1.6rem;
  }
  @include mq-min(lg) {
    margin-left: 0;
  }
}

.p-rooms-list li + li {
  @include mq-min(md) {
    margin-top: 5px;
  }
}

.p-rooms-gallery {
  margin-top: 20px;
  @include mq-min(md) {
    display: contents;
  }
}

.p-rooms-gallery__main {
  position: relative;
  padding-right: 10px;
  @include mq-min(md) {
    width: 100%;
    padding-right: 0;
  }
}

.p-rooms-gallery__main::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 10px);
  height: 100%;
  background-color: #DBD6CC;
  display: block;
  mix-blend-mode: multiply;
  @include mq-min(md) {
    width: 100%;
    height: 100%;
  }
}

.p-rooms-gallery__main img {
  position: relative;
  border: 1px solid #FFF;
  z-index: 4;
  mix-blend-mode: normal;
  image-rendering: -webkit-optimize-contrast;
  @include mq-min(md) {
    border: 3px solid #FFF;
  }
}

.p-rooms-gallery__sub {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 60px;
  @include mq-min(md) {
    margin-top: 46px;
  }
}

.p-rooms-gallery__sub-image {
  width: calc((100% - 5px) / 2);
  @include mq-min(md) {
    width: calc((100% - 5px) / 2);
    height: 100%;
    cursor: pointer;
    transition: opacity .3s;
  }
  @include mq-min(lg) {
    width: 165px;
    height: 157px;
  }
  &:hover {
    @include mq-min(md) {
      opacity: .7;
    }
  }
}

.p-rooms-gallery__sub-image img {
  image-rendering: -webkit-optimize-contrast;
  @include mq-min(md) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-rooms-button {
  margin-top: 20px;

  @include mq-min(md) {
    width: 232px;
  }
}

.p-rooms-section--double {
  position: relative;
  padding: 50px 0;

  @include mq-min(lg) {
    padding: 100px 0;
    scroll-margin-top: 160px;
  }
}

.p-rooms-section--double__inner {
  position: relative;
  width: fit-content;
  margin: auto;
}

.p-rooms-section--double__inner::before {
  content: '';
  position: absolute;
  top: -10px;
  right: 0;
  width: min(500px, 66.666vw);
  height: min(748px, 99.733vw);
  background-color: #DBD6CC;
  mix-blend-mode: multiply;
  transform: translateX(100%);
  opacity: 0;
  transition: transform .6s cubic-bezier(0.565, 0.43, 0.24, 0.92), opacity .6s;
  @include mq-min(md) {
    top: -100px;
    right: -320px;
    width: 774px;
    height: 750px;
  }
}

.is-show.p-rooms-section--double__inner::before {
  transform: translateX(0);
  opacity: 1;
}


.p-rooms-section--double__inner::after {
  content: '';
  position: absolute;
  top: -90px;
  right: -40px;
  width: min(400px, 53.333vw);
  height: min(470px, 62.666vw);
  background: url(../images/common/bg_monstera_sp.png) no-repeat center / cover;
  mix-blend-mode: multiply;

  @include mq-min(md) {
    top: -500px;
    right: -400px;
    width: 600px;
    height: 704px;
    background: url(../images/common/bg_monstera_pc.png) no-repeat center / cover;
    z-index: -1;
  }
}

.p-rooms-section--double__grid {
  position: relative;
  transform: translateY(60px);
  transition: transform .7s .5s, opacity .7s .5s;
  opacity: 0;
  @include mq-min(md) {
    display: grid;
    grid-template-columns: 160px 180px repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr) 100px;
    align-items: end;
  }
  @include mq-min(lg) {
    grid-template-columns: repeat(2, 200px) repeat(4, 1fr);
    grid-template-rows: 180px 204px 157px 100px;
  }
}


.is-show .p-rooms-section--double__grid {
  transform: translateY(0);
  opacity: 1;
}

.p-rooms-section--double__grid .p-rooms-heading-set--double {
  @include mq-min(md) {
    grid-area: 1 / 1 / 2 / 7;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  @include mq-min(lg) {
    grid-area: 1 / 1 / 2 / 3;
    display: block;
    margin-right: 29px;
  }
}
.p-rooms-section--double__grid .p-rooms-gallery__main {
  @include mq-min(md) {
    grid-area: 2 / 3 / 4 / 7;
  }
  @include mq-min(lg) {
    grid-area: 1 / 3 / 4 / 7;
  }
}
.p-rooms-section--double__grid .p-rooms-gallery__sub {
  @include mq-min(md) {
    grid-area: 2 / 1 / 4 / 3;
    margin-right: 53px;
  }
  @include mq-min(lg) {
    grid-area: 2 / 1 / 4 / 3;
  }
}
.p-rooms-section--double__grid .p-rooms-button {
  @include mq-min(md) {
    grid-area: 4 / 3 / 5 / 5;
  }
  @include mq-min(lg) {
    grid-area: 4 / 3 / 5 / 5;
  }
}



.p-rooms-section--amenity {
  position: relative;
  padding: 50px 0;
  @include mq-min(lg) {
    padding: 100px 0;
  }
}

.p-rooms-section--amenity::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1560px;
    height: 2px;
    background-color: $accent_color;
  }
}

.p-rooms-heading {
  font-size: 1.3rem;
  @include ten-mincho;
  @include mq-min(md) {
    display: flex;
    align-items: center;
    font-size: 2.2rem;
  }
}
.p-rooms-heading span {
  display: block;
  font-size: 1.9rem;
  @include trajan-normal;
  letter-spacing: 0.07em;
  border-bottom: 1px solid $border_color;
  margin-bottom: 2px;
  @include mq-min(md) {
    font-size: 5rem;
    border-bottom: none;
    margin-bottom: 0;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid $border_color;
  }
}

.p-rooms-amenity {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  margin-top: 30px;
  margin-bottom: 50px;

  @include mq-min(md) {
    row-gap: 70px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.p-rooms-amenity__item {
  width: 50%;
  display: flex;
  align-items: center;
  @include mq-min(md) {
    width: 25%;
  }
}

.p-rooms-amenity__image {
  width: auto;
  height: min(74px, 9.8666vw);
}

.p-rooms-amenity__text {
  flex-grow: 1;
  font-size: 1.2rem;
  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

.p-rooms-check-in-out {
  padding-top: 50px;
  border-top: 1px solid $border_color;
  @include mq-min(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 100px;
  }
  @include mq-min(xl) {
    justify-content: space-between;
  }
}

.p-rooms-check-in-out__text {
  font-size: 1.2rem;
  @include ten-mincho;
  display: flex;
  align-items: center;
  width: min(450px, 60vw);
  margin: 0 auto;
  @include mq-min(md) {
    width: auto;
    font-size: 2.2rem;
    margin: 0;
  }
}

.p-rooms-check-in-out__text + .p-rooms-check-in-out__text {
  margin-top: 20px;
  @include mq-min(md) {
    margin-top: 0;
  }
}

.p-rooms-check-in-out__text span {
  display: inline-block;
  font-size: 1.9rem;
  @include trajan-normal;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid $border_color;
  @include mq-min(md) {
    padding-right: 20px;
    margin-right: 20px;
    font-size: 5rem;
  }
}

.p-rooms-bg-hibiscus::before {
  content: '';
  position: absolute;
  top: -90px;
  left: -88px;
  width: 230px;
  height: 232px;
  background: url(../images/common/bg_hibiscus_sp.png) no-repeat center/cover;
  mix-blend-mode: multiply;
  z-index: -1;

  @include mq-min(md) {
    top: -340px;
    left: -500px;
    width: 657px;
    height: 663px;
    background: url(../images/common/bg_hibiscus_pc.png) no-repeat center/cover;
  }
}