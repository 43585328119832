@use '../setting/index' as *;

.p-info-section {
  padding: 50px 0;
  @include mq-min(md) {
    padding: 100px 0;
  }
}

.p-info-heading-set {
  padding-bottom: 4px;
  border-bottom: 1px solid $border_color;
  @include mq-min(md) {
    position: relative;
    display: flex;
    align-items: flex-start;
    border: none;
    padding-bottom: 30px;
  }
}

.p-info-heading-set::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: min(1560px, 100vw);
    height: 2px;
    background-color: $border_color;
  }
}

.p-info-date {
  font-size: 1.2rem;
  color: #333;
  @include mq-min(md) {
    font-size: 1.8rem;
    padding-top: 16px;
  }
}

.p-info-heading {
  font-size: 1.9rem;
  @include ten-mincho;
  margin-top: 5px;
  @include mq-min(md) {
    font-size: 4rem;
    margin-top: 0;
    margin-left: 100px;
  }
}

.p-info-text-wrap {
  margin-top: 30px;
  @include mq-min(md) {
    margin-top: 60px;
  }
}

.p-info-text-wrap p {
  font-size: 1.2rem;
  color: #333;
  @include mq-min(md) {
    font-size: 2.4rem;
  }
}

.p-info-text-wrap p + p {
  margin-top: 2em;
}

.p-info-text {
  font-size: 1.2rem;
  color: #333;
  @include mq-min(md) {
    font-size: 2.4rem;
  }
}

.p-info-text + .p-info-text {
  margin-top: 2em;
}

.p-info-button-wrap {
  margin-top: 50px;
  @include mq-min(md) {
    width: 232px;
    margin: 100px auto 0;
  }
}