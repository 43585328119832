@use '../setting/index' as *;

* {
  box-sizing: border-box;
  letter-spacing: 0.1em;
}

html {
  font-size: 62.5%;
  line-height: 1.5;
  font-family: $base_font;
  font-weight: 500;
  color: $base_color;
}


main {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: #FAF7F2;
}

img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
