@use '../setting/index' as *;

.p-sightseeing-section {
  padding: 50px 0;
  @include mq-min(md) {
    padding: 100px 0;
  }
}

.p-sightseeing-heading--large {
  font-size: 2.5rem;
  @include ten-mincho;
  text-align: center;
  letter-spacing: 0.05em;
  padding: 23px 1em 27px;
  background: url(../images/common/heading_lower_bg_sp.jpg) no-repeat center / cover;

  @include mq-min(md) {
    font-size: 4rem;
    line-height: 1.1;
    letter-spacing: 0.1em;
    padding: 39px 1em;
    background: url(../images/common/heading_lower_bg_pc.jpg) no-repeat center / cover;
  }
  @include mq-min(lg) {
    font-size: 5rem;
  }
}

.p-sightseeing-heading--large span {
  position: relative;
}
.p-sightseeing-heading--large span::before {
  content: '';
  position: absolute;
  width: 115%;
  height: 1px;
  background-color: $border_color;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  @include mq-min(md) {
    content: none;
  }
}

.p-sightseeing-read {
  font-size: 1.3rem;
  @include ten-mincho;
  line-height: 2;
  letter-spacing: 0;
  margin-top: 50px;
  @include mq-min(md) {
    font-size: 2rem;
    line-height: 1.5;
    margin-top: 40px;
  }
}

.p-sightseeing-map {
  position: relative;
  margin-top: 30px;
  padding-left: 5px;
  box-shadow: 5px 5px #DBD6CC;
  @include mq-min(md) {
    margin-top: 100px;
    padding-left: 10px;
    box-shadow: 10px 10px #DBD6CC;
  }
}

.p-sightseeing-map picture {
  display: block;
}

.p-sightseeing-map img {
  image-rendering: -webkit-optimize-contrast;
  @include mq-min(md) {
    border: 3px solid #fff;
  }
}

.p-sightseeing-button {
  margin-top: 30px;
  @include mq-min(md) {
    width: 232px;
    margin: 100px auto 0;
  }
}

.p-sightseeing-grid-odd {
  display: contents;
  transform: translateY(60px);
  transition: transform .7s .5s, opacity .7s .5s;
  opacity: 0;
  @include mq-min(md) {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr minmax(0px, 300px);
    grid-row-gap: 64px;
  }
}

.is-show .p-sightseeing-grid-odd {
  transform: translateY(0);
  opacity: 1;
}

.p-sightseeing-grid-even {
  display: contents;
  transform: translateY(60px);
  transition: transform .7s .5s, opacity .7s .5s;
  opacity: 0;
  @include mq-min(md) {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr minmax(1fr, 300px);
    grid-row-gap: 64px;
  }
}

.is-show .p-sightseeing-grid-even {
  transform: translateY(0);
  opacity: 1;
}

.p-sightseeing-grid-odd .p-sightseeing-heading-set {
  @include mq-min(md) {
    grid-area: 1 / 3 / 2 / 4;
  }
}

.p-sightseeing-grid-odd .p-sightseeing-gallery-main {
  @include mq-min(md) {
    grid-area: 1 / 1 / 2 / 3;
  }
}

.p-sightseeing-grid-odd .p-sightseeing-gallery-sub {
  @include mq-min(md) {
    grid-area: 2 / 1 / 3 / 4;
  }
}

.p-sightseeing-grid-even .p-sightseeing-heading-set {
  @include mq-min(md) {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.p-sightseeing-grid-even .p-sightseeing-gallery-main {
  @include mq-min(md) {
    grid-area: 1 / 2 / 2 / 4;
  }
}

.p-sightseeing-grid-even .p-sightseeing-gallery-sub--reverse {
  @include mq-min(md) {
    grid-area: 2 / 1 / 3 / 4;
  }
}


.p-sightseeing-heading-set {
  display: contents;
  @include mq-min(md) {
    display: block;
  }
}

%heading-left-justified {
  display: flex;
  align-items: flex-start;
  padding: 20px 30px 10px 45px;
  margin-left: calc(50% - 50vw);
  mix-blend-mode: multiply;
  @include mq-min(md) {
    background-color: transparent;
    margin-left: 0;
    padding: 0;
    mix-blend-mode: normal;
  }
}

%heading-right-justified {
  display: flex;
  align-items: flex-start;
  padding: 20px 30px 10px 20px;
  margin-right: calc(50% - 50vw);
  margin-left: -15px;
  background-color: #EBE6DE;
  mix-blend-mode: multiply;
  @include mq-min(md) {
    background-color: transparent;
    margin-right: 0;
    margin-left: 0;
    padding: 0;
    mix-blend-mode: normal;
  }
}

.p-sightseeing-heading-flex01 {
  @extend %heading-left-justified;
  background-color: rgba($color: #491F00, $alpha: .5);
}

.p-sightseeing-heading-flex02 {
  @extend %heading-right-justified;
}

.p-sightseeing-heading-flex03 {
  @extend %heading-left-justified;
  background-color: #DBD6CC;
}

.p-sightseeing-heading-flex04 {
  @extend %heading-right-justified;
}

.p-sightseeing-heading-num {
  font-size: 3.5rem;
  @include trajan-normal;
  padding-top: 3px;
  padding-left: 37px;
  background: url(../images/icon/icon_recommend.svg) no-repeat left 4px / 31px 36px;
  @include mq-min(md) {
    font-size: 7rem;
    padding-top: 8px;
    padding-left: 74px;
    background: url(../images/icon/icon_recommend.svg) no-repeat left top / 61px 72px;
    line-height: 1;
  }
}

.p-sightseeing-heading {
  position: relative;
  width: fit-content;
  font-size: 1.3rem;
  @include ten-mincho;
  @include mq-min(md) {
    font-size: 1.8rem;
  }
  &::before {
    content: '';
    position: absolute;
    top: 53%;
    left: 0;
    right: 0;
    border-top: 1px solid $border_color;
  }
}

.p-sightseeing-heading span {
  display: block;
  font-size: 1.9rem;
  @include trajan-normal;
  @include mq-min(md) {
    font-size: 2.4rem;
    margin-bottom: 15px;
  }
}

.p-sightseeing-marker {
  font-size: 1.8rem;
  @include ten-mincho;
  margin-top: 30px;
  line-height: 2;
  @include mq-min(md) {
    font-size: 3rem;
    line-height: 1.5;
    margin-top: 20px;
  }
}

.p-sightseeing-marker span {
  display: inline-block;
  padding: 0 5px;
  background: linear-gradient(transparent 60%, rgba(219, 214, 204, .5) 0%);
}

.p-sightseeing-text {
  font-size: 1.3rem;
  color: #333;
  line-height: 2;
  margin-top: 30px;
  @include mq-min(md) {
    font-size: 1.6rem;
    margin-top: 20px;
  }
}

.p-sightseeing-gallery-main {
  position: relative;
  display: block;
  padding-right: 10px;
  margin-top: 30px;
  @include mq-min(md) {
    padding-right: 0;
    margin-top: auto;
  }
}

.p-sightseeing-gallery-main::after {
  content: '';
  position: absolute;
  width: calc(100% - 10px);
  height: 100%;
  top: 10px;
  left: 10px;
  background-color: #DBD6CC;
  mix-blend-mode: multiply;
  z-index: -1;
  @include mq-min(md) {
    top: 10px;
    left: 10px;
    width: 100%;
    height: auto;
    padding-top: 69%;
  }
}

.p-sightseeing-gallery-main img {
  border: 1px solid #fff;
  image-rendering: -webkit-optimize-contrast;
  @include mq-min(md) {
    border-width: 3px;
  }
}

%gallery-sub {
  margin-top: 20px;
  @include mq-min(md) {
    display: flex;
    gap: 18px;
  }
}

.p-sightseeing-gallery-sub {
  @extend %gallery-sub;
  @include mq-min(md) {
    flex-direction: row-reverse;
  }
}

.p-sightseeing-gallery-sub--reverse {
  @extend %gallery-sub;
  @include mq-min(md) {
    flex-direction: row;
  }
}

.p-sightseeing-gallery-sub__image {
  width: 51.734vw;
  height: 33.334vw;
  @include mq-min(md) {
    width: 100%;
    height: min(300px, 23.4375vw);
    transition: opacity .3s;
  }
}

.p-sightseeing-gallery-sub__image:hover {
  @include mq-min(md) {
    opacity: .7;
    cursor: pointer;
  }
}

.p-sightseeing-gallery-sub .p-sightseeing-gallery-sub__image:first-of-type,
.p-sightseeing-gallery-sub .p-sightseeing-gallery-sub__image:last-of-type {
  margin-left: auto;
  @include mq-min(md) {
    margin-left: 0;
  }
}

.p-sightseeing-gallery-sub .p-sightseeing-gallery-sub__image:nth-of-type(2),
.p-sightseeing-gallery-sub .p-sightseeing-gallery-sub__image:last-of-type {
  margin-top: -20px;
  @include mq-min(md) {
    margin-top: 0;
  }
}

.p-sightseeing-gallery-sub .p-sightseeing-gallery-sub__image:first-of-type {
  @include mq-min(md) {
    margin-top: -40px;
  }
}

.p-sightseeing-gallery-sub .p-sightseeing-gallery-sub__image:last-of-type {
  @include mq-min(md) {
    margin-top: 40px;
  }
}

.p-sightseeing-gallery-sub--reverse .p-sightseeing-gallery-sub__image:nth-of-type(2) {
  margin-left: auto;
  @include mq-min(md) {
    margin-left: 0;
  }
}

.p-sightseeing-gallery-sub--reverse .p-sightseeing-gallery-sub__image:nth-of-type(2),
.p-sightseeing-gallery-sub--reverse .p-sightseeing-gallery-sub__image:last-of-type {
  margin-top: -20px;
  @include mq-min(md) {
    margin-top: 0;
  }
}

.p-sightseeing-gallery-sub--reverse .p-sightseeing-gallery-sub__image:first-of-type {
  @include mq-min(md) {
    margin-top: -40px;
  }
}
.p-sightseeing-gallery-sub--reverse .p-sightseeing-gallery-sub__image:last-of-type {
  @include mq-min(md) {
    margin-top: 40px;
  }
}

.p-sightseeing-gallery-sub__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #fff;
  image-rendering: -webkit-optimize-contrast;
  @include mq-min(md) {
    border: none;
  }
}


// 背景の設定
.p-sightseeing-bg-strelitzia::before {
  content: '';
  position: absolute;
  top: -25px;
  right: -76px;
  width: min(500px, 66.667vw);
  height: min(718px, 95.734vw);
  background: url(../images/common/bg_strelitzia_sp.png) no-repeat center / cover;
  transform: scale(-1, 1) rotate(13deg);
  z-index: -1;
  @include mq-min(md) {
    top: 450px;
    right: -600px;
    width: 808px;
    height: 1161px;
    background: url(../images/common/bg_strelitzia_pc.png) no-repeat center / cover;
    transform: scale(-1, 1);
  }
}

.p-sightseeing-bg-square01::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: -83px;
    left: -320px;
    width: 748px;
    height: 760px;
    background-color: rgba($color: #491F00, $alpha: .5);
    mix-blend-mode: multiply;
    transform: translateX(-100%);
    opacity: 0;
    transition: transform .6s cubic-bezier(0.565, 0.43, 0.24, 0.92), opacity .6s;
  }
}

.is-show.p-sightseeing-bg-square01::before {
  transform: translateX(0);
  opacity: 1;
}

.p-sightseeing-bg-palm::after {
  content: '';
  position: absolute;
  top: -100px;
  right: -120px;
  width: min(702px, 93.6vw);
  height: min(550px, 73.334vw);
  background: url(../images/common/bg_palm_sp.png) no-repeat center / cover;
  mix-blend-mode: multiply;
  z-index: -1;
  @include mq-min(md) {
    right: initial;
    top: -90px;
    left: -610px;
    width: 1006px;
    height: 788px;
    background: url(../images/common/bg_palm_pc.png) no-repeat center / cover;
    transform: rotate(-110deg);
  }
}

.p-sightseeing-bg-square02::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: -100px;
    right: -320px;
    width: 748px;
    height: 760px;
    background-color: #EBE6DE;
    mix-blend-mode: multiply;
    transform: translateX(100%);
    opacity: 0;
    transition: transform .6s cubic-bezier(0.565, 0.43, 0.24, 0.92), opacity .6s;
  }
}
.is-show.p-sightseeing-bg-square02::before {
  transform: translateX(0);
  opacity: 1;
}

.p-sightseeing-bg-monstera::after {
  content: '';
  position: absolute;
  top: -155px;
  left: -66px;
  width: min(400px, 53.334vw);
  height: min(470px, 62.667vw);
  background: url(../images/common/bg_monstera_sp.png) no-repeat center / cover;
  mix-blend-mode: multiply;
  transform: scale(-1, 1);
  z-index: -1;
  @include mq-min(md) {
    left: initial;
    top: -376px;
    right: -410px;
    width: 600px;
    height: 704px;
    background: url(../images/common/bg_monstera_pc.png) no-repeat center / cover;
    transform: scale(1, 1);
  }
}

.p-sightseeing-bg-square03::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: -100px;
    left: -320px;
    width: 748px;
    height: 760px;
    background-color: #DBD6CC;
    mix-blend-mode: multiply;
    transform: translateX(-100%);
    opacity: 0;
    transition: transform .6s cubic-bezier(0.565, 0.43, 0.24, 0.92), opacity .6s;
  }
}
.is-show.p-sightseeing-bg-square03::before {
  transform: translateX(0);
  opacity: 1;
}

.p-sightseeing-bg-hibiscus::after {
  content: '';
  position: absolute;
  top: -110px;
  right: -66px;
  width: min(460px, 61.334vw);
  height: min(464px, 61.867vw);
  background: url(../images/common/bg_hibiscus_sp.png) no-repeat center / cover;
  mix-blend-mode: multiply;
  transform: scale(-1, 1);
  z-index: -1;
  @include mq-min(md) {
    left: initial;
    top: -458px;
    left: -504px;
    right: initial;
    width: 657px;
    height: 662px;
    background: url(../images/common/bg_hibiscus_pc.png) no-repeat center / cover;
    transform: scale(1, 1);
  }
}

.p-sightseeing-bg-square04::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: -100px;
    right: -320px;
    width: 748px;
    height: 760px;
    background-color: #EBE6DE;
    mix-blend-mode: multiply;
    transform: translateX(100%);
    opacity: 0;
    transition: transform .6s cubic-bezier(0.565, 0.43, 0.24, 0.92), opacity .6s;
  }
}
.is-show.p-sightseeing-bg-square04::before {
  transform: translateX(0);
  opacity: 1;
}

.p-sightseeing-bg-orchid::after {
  content: '';
  position: absolute;
  top: -138px;
  left: -74px;
  width: min(500px, 66.667vw);
  height: min(440px, 58.667vw);
  background: url(../images/common/bg_orchid_sp.png) no-repeat center / cover;
  mix-blend-mode: multiply;
  transform: scale(-1, 1);
  z-index: -1;
  @include mq-min(md) {
    left: initial;
    top: -400px;
    right: -502px;
    left: initial;
    width: 694px;
    height: 612px;
    background: url(../images/common/bg_orchid_pc.png) no-repeat center / cover;
    transform: scale(1, 1);
  }
}

.p-sightseeing-bg-monstera--pc::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    bottom: -154px;
    left: -494px;
    width: 600px;
    height: 704px;
    background: url(../images/common/bg_monstera_pc.png) no-repeat center / cover;
    mix-blend-mode: multiply;
    transform: scale(-1, 1);
    z-index: -1;
  }
}
