@use '../setting/index' as *;
@use '../../../node_modules/slick-carousel/slick/slick.css';
@use '../../../node_modules/slick-carousel/slick/slick-theme.css';


.p-top-fix-bg {
  position: relative;
  height: 93.333vw;
  max-height: 630px;
  @include mq-min(md) {
    max-height: 100%;
    height: clamp(500px, 41.5104vw, 797px);
  }
  @include mq-min(lg) {
    height: clamp(626px, 41.5104vw, 797px);
  }
} 

.p-top-slider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 111vw;
  max-height: 828px;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  @include mq-min(md) {
    height: clamp(500px, 41.5104vw, 797px);
  }
  @include mq-min(lg) {
    height: clamp(626px, 41.5104vw, 797px);
  }
}

.p-top-slider__item img {
  height: 111vw;
  object-fit: cover;
  @include mq-min(md) {
    height: clamp(500px, 41.5104vw, 797px);
    object-fit: cover;
  }
  @include mq-min(lg) {
    height: clamp(626px, 43vw, 800px);
  }
}

.customize-support .p-top-slider {
  padding-top: 46px;
  @include mq-min(md) {
    padding-top: 32px;
  }
}

.p-top-slider.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.p-top-slider .slick-dots {
  display: none !important;
}

.p-top-slider__item--video {
  display: none;
  @include mq-max(md) {
    display: block;
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
  }
}

.p-top-slider__item--video video {
  @include mq-min(md) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: clamp(500px, 41.5104vw, 797px);
    object-fit: cover;
  }
  @include mq-min(lg) {
    height: clamp(626px, 43vw, 800px);
  }
}

.p-top-mv-heading {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  height: min(828px, 111vw);
  display: grid;
  align-content: center;
  color: #fff;
  font-size: 1.4rem;
  font-size: clamp(1.4rem, 3.733vw, 3rem);
  @include ten-mincho;
  text-align: center;
  text-shadow: 0 0 10px #AA9370, 0 0 10px #AA9370, 0 0 10px #AA9370;
  padding-top: 26px;
  opacity: 0;
  @include mq-min(md) {
    top: 27vw;
    transform: translateX(-50%);
    font-size: 2.1875vw;
    height: auto;
    max-height: 100%;
    padding-top: 0;
  }
  @include mq-min(xl) {
    top: 380px;
    font-size: 2.8rem;
  }
}


.p-top-mv-heading span {
  position: relative;
  display: block;
  width: max-content;
  font-size: clamp(2.5rem, 6.667vw, 4.5rem);
  @include trajan-normal;
  padding-bottom: 7px;
  margin: 0 auto;
  @include mq-min(md) {
    font-size: 3.90625vw;
    padding: 0 40px 10px 40px;
  }
  @include mq-min(xl) {
    font-size: 5rem;
  }
}

.p-top-mv-heading span::before {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  box-shadow: 0 0 10px #AA9370, 0 0 10px #AA9370, 0 0 10px #AA9370;
  @include mq-min(md) {
    height: 2px;
  }
}

.wf-active .p-top-mv-heading {
  animation: fade-in-text 3s ease .2s forwards;
}

@keyframes fade-in-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.p-top-section--rooms {
  position: relative;
  padding: 30px 0 132px;
  mix-blend-mode: multiply;
  @include mq-min(md) {
    padding: 100px 0;
    z-index: 3;
  }
}

.p-top-heading01-wrap {
  @include mq-min(md) {
    background: url(../images/top/top_heading_bg01_pc.png) no-repeat right center / cover;
    margin-left: calc(50% - 50vw);
  }
  @include mq-min(xxl) {
    background-size: contain;
  }
}

.p-top-heading01 {
  font-size: 1.4rem;
  @include ten-mincho;
  letter-spacing: 0;

  @include mq-min(md) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 1240px;
    font-size: 2.2rem;
    text-align: left;
    line-height: 1;
    padding: 30px 0 33px 40px;
    margin-left: auto;
  }
}

.p-top-heading01 span {
  position: relative;
  display: block;
  margin: 0 calc(50% - 50vw);
  font-size: 2.5rem;
  @include trajan-normal;
  padding: 3px 0 3px 45px;
  margin-bottom: 10px;
  background-color: rgba($color: $accent_color, $alpha: .5);
  clip-path: polygon(0 0, 45% 0, 53% 100%, 0% 100%);

  @include mq-min(md) {
    display: inline-block;
    font-size: 5rem;
    padding-right: 14px;
    padding-left: 0;
    margin-right: 20px;
    border-right: 1px solid $border_color;
    background-color: transparent;
    background-image: none;
    margin: 0 20px 0 0;
    clip-path: none;
  }
}

.p-top-heading01 span::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(../images/top/heading_bg_mask.png);
  mix-blend-mode: multiply;
  clip-path: polygon(0 0, 45% 0, 53% 100%, 0% 100%);
  z-index: -1;
  @include mq-min(md){
    content: none;
  }
}

.p-top-heading02-wrap {
  @include mq-min(md) {
    background: url(../images/top/top_heading_bg02_pc.png) no-repeat left center / cover;
    margin-right: calc(50% - 50vw);
  }
  @include mq-min(xxl) {
    background-size: contain;
  }
}

.p-top-heading02 {
  font-size: 1.4rem;
  @include ten-mincho;
  text-align: right;
  letter-spacing: 0;
  mix-blend-mode: multiply;
  @include mq-min(md) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 1240px;
    font-size: 2.2rem;
    letter-spacing: 0.1em;
    line-height: 1;
    padding: 30px 40px 33px 0;
  }
}

.p-top-heading02 span {
  display: block;
  margin: 0 calc(50% - 50vw);
  font-size: 2.5rem;
  @include trajan-normal;
  letter-spacing: 0.05em;
  padding: 3px 45px 3px 0;
  margin-bottom: 10px;
  background-color: #DBD6CC;
  clip-path: polygon(17% 0, 100% 0, 100% 100%, 10% 100%);

  @include mq-min(md) {
    font-size: 5rem;
    background: none;
    display: inline-block;
    padding-right: 15px;
    border-right: 1px solid $border_color;
    background-color: transparent;
    background-image: none;
    margin: 0 17px 0 0;
    clip-path: none;
    letter-spacing: 0.14em;
  }
}

.p-top-heading03-wrap {
  @include mq-min(md) {
    position: relative;
    background: url(../images/top/top_heading_bg03_pc.png) no-repeat right center / cover;
    margin-left: calc(50% - 50vw);
    z-index: 5;
  }
  @include mq-min(xxl) {
    background-size: contain;
  }
}

.p-top-heading03 {
  font-size: 1.4rem;
  @include ten-mincho;

  @include mq-min(md) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 1280px;
    font-size: 2.2rem;
    padding: 19px 40px 20px;
    margin-left: auto;
  }
}

.p-top-heading03 span {
  display: block;
  margin: 0 calc(50% - 50vw);
  font-size: 2.5rem;
  @include trajan-normal;
  padding: 3px 0 3px 45px;
  margin-bottom: 10px;
  background-color: #FAF7F2;
  background-image: url(../images/top/heading_bg_mask.png);
  clip-path: polygon(0 0, 74% 0, 78% 100%, 0% 100%);

  @include mq-min(md) {
    display: inline-block;
    font-size: 5rem;
    padding-right: 47px;
    padding-left: 0;
    margin-right: 20px;
    border-right: 1px solid $border_color;
    background-color: transparent;
    background-image: none;
    margin: 0 20px 0 0;
    clip-path: none;
  }
}

.p-top-text-wrap {
  color: #333;
  margin-top: 28px;
  @include mq-min(md) {
    position: absolute;
    top: 216px;
    left: 0;
    max-width: 278px;
    padding-left: 40px;
    margin-top: 0;
  }
}

.p-top-text-wrap--service {
  margin-top: 28px;
  @include mq-min(md) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;
    z-index: 10;
  }
}

.p-top-text {
  font-size: 1.3rem;
  line-height: 2;
  letter-spacing: 0;
  @include mq-min(md) {
    font-size: 1.6rem;
    line-height: 1.7;
  }
}
.p-top-text--service {
  font-size: 1.3rem;
  color: #333;
  line-height: 2;
  letter-spacing: 0;
  @include mq-min(md) {
    width: min(737px, 100%);
    font-size: 1.6rem;
  }
}

.p-top-button-wrap--rooms {
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  @include mq-min(md) {
    position: static;
    transform: none;
    margin-top: 119px;
  }
}

.p-top-figure-wrap {
  margin-top: 24px;
  @include mq-min(md) {
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
    padding-left: 278px;
  }
}

.p-top-figure {
  width: min(550px, 73.33vw);
  color: #333;
  margin: 0 auto;
  @include mq-min(md) {
    width: min(476px, 100%);
  }
}

.p-top-figure > a {
  display: block;
  transition: transform .3s, box-shadow .3s, opacity .3s;
  box-shadow: 10px 10px #dbd6cc;

}

.p-top-figure > a:hover {
  @include mq-min(md) {
    transform: translate(3px, 3px);
    box-shadow: 0px 0px transparent;
    opacity: .7;
  }
}

.p-top-figure + .p-top-figure {
  margin-top: 40px;
  @include mq-min(md) {
    margin-top: 60px;
    margin-left: -40px;
  }
}

.p-top-figure img {
  border: 2px solid #fff;
}

.p-top-figure figcaption {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  @include ten-mincho;
  margin-top: 20px;
  @include mq-min(md) {
    font-size: 1.6rem;
    margin-top: 16px;
  }

}

.p-top-figure figcaption span {
  font-size: 1.9rem;
  @include trajan-normal;
  padding-right: 8px;
  padding-left: 9px;
  margin-right: 10px;
  border-right: 1px solid #000;
  letter-spacing: 0.1em;
  @include mq-min(md) {
    font-size: 3rem;
    padding-left: 0;
  }
}

.p-top-section--service {
  position: relative;
  padding: 30px 0 136px;
  background: url(../images/top/top_content_bg_sp.jpg) no-repeat center / cover;

  @include mq-min(md) {
    padding-top: 100px;
    z-index: 2;
  }
}

.p-top-button-wrap--service {
  position: absolute;
  bottom: -85px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  @include mq-min(md) {
    position: static;
    transform: none;
    width: 232px;
  }
}

.p-top-slider--service {
  margin-top: 24px;

  @include mq-min(md) {
    max-width: 1120px;
    width: 100%;
    margin: 50px auto 0;
    padding-bottom: 0;
  }
}

.p-top-slider--service__item {
  position: relative;
  display: block;
  overflow: hidden;
}

.p-top-slider--service__item img {
  height: 100%;
}
.p-top-slider--service__item::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 0%, #491F00 100%);
    mix-blend-mode: multiply;
    transform: translateY(100%);
    transition: transform .3s;
  }
}
.p-top-slider--service__item::after {
  @include mq-min(md) {
    content: 'VIEW MORE';
    color: #fff;
    font-size: 1.6rem;
    @include trajan-normal;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    transition: transform .3s;
  }
}
.p-top-slider--service__item:hover::before,
.p-top-slider--service__item:hover::after {
  transform: translateY(0);
}

.p-top-slider--service__text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
  font-size: 1.4rem;
  @include ten-mincho;
  background: linear-gradient(to bottom, transparent 0%, #491F00 100%);
  padding: 10px;
  @include mq-min(md) {
    font-size: 1.4rem;
    min-height: 121px;
    transition: opacity .3s;
  }
}

.p-top-slider--service__item:hover .p-top-slider--service__text {
  @include mq-min(md) {
    opacity: 0;
  }
}

.p-top-slider--service__text span {
  display: block;
  width: fit-content;
  font-size: 1.9rem;
  @include trajan-normal;
  border-bottom: 1px solid #fff;
  @include mq-min(md) {
    font-size: 2rem;
  }
}

.slick-loading .slick-list {
  background: none !important;
}

.p-top-slider--service .slick-slide {
  @include mq-min(md) {
    margin: 0 5px;
  }
}

.p-top-slider--service .slick-dotted.slick-slider {
  margin-bottom: 0;
}

.p-top-slider--service .slick-dots {
  text-align: left;
  bottom: -10px;
  @include mq-min(md) {
    text-align: center;
    bottom: -30px;
  }
}

.p-top-slider--service .slick-dots li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 2px;
  border: 1px solid $border_color;
  background-color: transparent;
  @include mq-min(md) {
    width: 16px;
    height: 16px;
  }
}

.p-top-slider--service .slick-dots li.slick-active {
  background-color: $border_color;
}

.p-top-slider--service .slick-dots li button {
  width: 100%;
  height: 100%;
  padding: 0;
}

.p-top-slider--service .slick-dots li button::before {
  content: none;
}

.p-top-slider--service .slick-prev.slick-arrow {
  @include mq-min(md) {
    position: absolute;
    top: 42%;
    left: -24px;
    width: 20px;
    height: 20px;
    border-top: 1px solid $border_color;
    border-right: 1px solid $border_color;
    transform: rotate(-135deg);
  }
  &::before {
    content: none;
  }
}
.p-top-slider--service .slide-arrow.next-arrow {
  @include mq-min(md) {
    position: absolute;
    top: 42%;
    right: -24px;
    width: 20px;
    height: 20px;
    border-top: 1px solid $border_color;
    border-right: 1px solid $border_color;
    transform: rotate(45deg);
  }
  &::before {
    content: none;
  }
}

.p-top-banner-wrap {
  position: relative;
  display: block;
  padding: 50px 45px;
  @include mq-min(md) {
    max-width: 1280px;
    padding: 100px 40px;
    margin: auto;
  }
}

.p-top-banner {
  position: relative;
  display: block;
  box-shadow: 10px 10px #DBD6CC;
  z-index: 2;
  @include mq-min(md) {
    max-width: 1190px;
    transition: transform .3s, box-shadow .3s, opacity .3s;
  }
}

.p-top-banner:hover {
  @include mq-min(md) {
    box-shadow: 7px 7px transparent;
    transform: translate(3px, 3px);
    opacity: .8;
  }
}


.p-top-banner picture {
  display: block;
}

.p-top-banner img {
  border: 1px solid #fff;
  @include mq-min(md) {
    border: 3px solid #fff;
  }
}

.p-top-info {
  position: relative;
  padding: 30px 0 18px;
  background: url(../images/top/top_content_bg_sp.jpg) no-repeat center / cover;

  @include mq-min(md) {
    padding: 100px 0 140px;
    background: url(../images/top/top_content_bg_pc.jpg) no-repeat center / cover;
  }
}

.p-top-info__content-wrap {
  position: relative;
  padding-right: 6px;
  z-index: 2;
  @include mq-min(md) {
    padding-right: 10px;
    margin-top: 50px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    @include mq-min(md) {
      width: 8px;
    }
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .3);
    mix-blend-mode: multiply;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $accent_color;
  }
}

.p-top-info__content + .p-top-info__content {
  border-top: 1px solid $border_color;
}

.p-top-info__content a {
  display: block;
  color: #333;
  padding: 20px 0;
  @include mq-min(md) {
    display: flex;
    align-items: flex-start;
    padding: 50px 0;
    transition: opacity .3s;
  }
}

.p-top-info__content a[href] .p-top-info__text {
  color: #069;
}

.p-top-info__content a[href]:hover .p-top-info__text {
  @include mq-min(md) {
    text-decoration: underline;
  }
}

.p-top-info__date {
  font-size: 1.3rem;
  @include mq-min(md) {
    font-size: 1.8rem;
    padding-top: 4px;
    white-space: nowrap;
  }
}

.p-top-info__text {
  font-size: 1.2rem;
  margin-top: 15px;
  @include mq-min(md) {
    font-size: 1.8rem;
    margin-top: 0;
    margin-left: 66px;
  }
}

.p-top-access {
  position: relative;
  padding: 50px 0;
  @include mq-min(md) {
    padding: 100px 0 160px;
  }
}

.p-top-bg-palm--reverse {
  &::after {
    @include mq-min(md) {
      content: "";
      position: absolute;
      top: -126px;
      left: -880px;
      width: 1186px;
      height: 1265px;
      background: url(../images/top/top_main_bg06.png) no-repeat center / contain;
      mix-blend-mode: multiply;
    }
  }
}

.p-top-bg-square {
  &::before {
    content: "";
    position: absolute;
    top: -25px;
    left: 0;
    width: min(370px, 49.333vw);
    height: min(734px, 97.866vw);
    background-color: #DBD6CC;
    z-index: 2;
    mix-blend-mode: multiply;
    transform: translateX(-100%);
    opacity: 0;
    transition: transform .6s cubic-bezier(0.565, 0.43, 0.24, 0.92), opacity .6s;
    @include mq-min(md) {
      width: 774px;
      height: 756px;
      left: -340px;
    }
  }
}

.p-top-access__map-bg {
  position: absolute;
  top: 96px;
  left: clamp(52px, 15.104vw, 116px);
  width: 73.333vw;
  height: 56.9013vw;
  @include mq-min(md) {
    top: initial;
    bottom: -10px;
    left: 50px;
    width: min(1190px, 92.96875vw);
    height: min(490px, 38.28125vw);
  }
}

.p-top-access__map-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  padding-top: 41.176%;
  max-height: 57.333vw;
  background-color: #DBD6CC;
  transform: translateY(60px);
  transition: transform .7s .5s, opacity .7s .5s;
  opacity: 0;
}

.is-show .p-top-access__map-bg::before {
  transform: translateY(0);
  opacity: 1;
}

.is-show.p-top-bg-square::before {
  transform: translateX(0);
  opacity: 1;
}

.p-top-access__fadein-wrap {
  position: relative;
  width: min(550px, 100%);
  margin: 0 auto;
  z-index: 2;
  transform: translateY(60px);
  transition: transform .6s .5s, opacity .6s .5s;
  opacity: 0;
  @include mq-min(md) {
    width: 100%;
  }
}

.is-show .p-top-access__fadein-wrap {
  transform: translateY(0);
  opacity: 1;
}

.p-top-access__heading-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 24px;
  z-index: 5;
  @include mq-min(md) {
    position: relative;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: start;
  }
}

.p-top-access__heading {
  position: relative;
  font-size: 1.4rem;
  @include ten-mincho;
  z-index: 3;
  @include mq-min(md) {
    display: flex;
    align-items: center;
    font-size: 2.2rem;
    margin-right: 40px;
  }
  @include mq-min(lg) {
    margin-right: 100px;
  }
  @include mq-min(xl) {
    margin-right: 120px;
  }
}

.p-top-access__heading span {
  display: block;
  font-size: 2.5rem;
  @include trajan-normal;
  @include mq-min(md) {
    display: inline-block;
    font-size: 5rem;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid $border_color;
  }
}

.p-top-access__text {
  position: relative;
  font-size: 1.2rem;
  color: #333;
  letter-spacing: 0;
  z-index: 3;
  @include mq-min(md) {
    width: 100%;
    font-size: 1.6rem;
    margin-top: 30px;
  }
}

.p-top-access__map-and-text-wrap {
  position: relative;
  margin-top: 30px;
}

.p-top-access__map {
  position: relative;
  width: 73.333vw;

  @include mq-min(md) {
    width: 100%;
    max-width: 1190px;
  }

  &::before {
    content: none;
    position: absolute;
    top: 8px;
    left: 10px;
    width: 100%;
    height: 100%;
    background-color: #DBD6CC;
  }
}

.p-top-access__map-inner {
  width: 100%;
  height: 100%;
  max-height: 57.333vw;
  padding-top: 77.5977%;
  z-index: 4;

  @include mq-min(md) {
    height: 100%;
    max-height: auto;
    padding-top: 41.176%;
  }

}

.p-top-access__map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  @include mq-min(md) {
    border: 3px solid #fff !important;
  }
}

.p-top-access__list {
  position: absolute;
  bottom: 76px;
  @include mq-min(md) {
    position: static;
    margin-top: 11px;
  }
}

.p-top-access__list-item {
  font-size: 1.2rem;
  color: #333;
  letter-spacing: 0;
  @include mq-min(md) {
    font-size: 1.8rem;
  }
}

.p-top-button-wrap--access {
  margin-top: 117px;
  @include mq-min(md) {
    position: absolute;
    top: -72px;
    right: 0;
    width: 232px;
    margin-top: 0;
    z-index: 5;
  }
}

// 背景画像
.p-top-bg-monstera {
  &::before {
    content: "";
    position: absolute;
    top: -50px;
    right: -28px;
    width: 200px;
    height: 235px;
    background: url(../images/common/bg_monstera_sp.png) no-repeat center / cover;
    mix-blend-mode: multiply;
    z-index: -1;
    @include mq-min(md) {
      top: -10px;
      right: -400px;
      width: 600px;
      height: 704px;
      background: url(../images/common/bg_monstera_pc.png) no-repeat center / cover;
    }
  }
}

.p-top-bg-hibiscus {
  &::after {
    @include mq-min(md) {
      content: "";
      position: absolute;
      bottom: -400px;
      left: -480px;
      width: 657px;
      height: 663px;
      background: url(../images/common/bg_hibiscus_pc.png) no-repeat center / cover;
      mix-blend-mode: multiply;
    }
  }
}

.p-top-bg-hibiscus--sp {
  &::after {
    content: "";
    position: absolute;
    top: -100px;
    left: -96px;
    width: 230px;
    height: 232px;
    background: url(../images/common/bg_hibiscus_sp.png) no-repeat center / cover;
    mix-blend-mode: multiply;
    @include mq-min(md) {
      content: none;
    }
  }
}

.p-top-bg-palm {
  &::before {
    content: "";
    position: absolute;
    bottom: -401px;
    right: -140px;
    width: 414px;
    height: 442px;
    background: url(../images/common/bg_palm_sp.png) no-repeat center / cover;
    mix-blend-mode: multiply;
    pointer-events: none;
    @include mq-min(md) {
      top: 100px;
      right: -850px;
      bottom: initial;
      width: 1186px;
      height: 1265px;
      background: url(../images/common/bg_palm_pc.png) no-repeat center / cover;
    }
  }
}

.p-top-bg-monstera--reverse {
  &::before {
    @include mq-min(md) {
      content: "";
      position: absolute;
      top: -370px;
      left: -420px;
      width: 600px;
      height: 704px;
      background: url(../images/common/bg_monstera_pc.png) no-repeat center / cover;
      transform: scale(-1, 1);
      mix-blend-mode: multiply;
      z-index: 1;
    }
  }
}

.p-top-bg-hibiscus--info {
  &::after {
    @include mq-min(md) {
      content: "";
      position: absolute;
      bottom: -550px;
      right: -434px;
      width: 657px;
      height: 663px;
      background: url(../images/common/bg_hibiscus_pc.png) no-repeat center / cover;
      mix-blend-mode: multiply;
      transform: scale(-1, 1);
    }
  }
}
