@use '../setting/index' as *;

%c-fix-bg {
  position: relative;
  height: 93.333vw;
  max-height: 630px;

  @include mq-min(md) {
    height: 41.5104vw;
    max-height: 797px;
  }

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 111vw;
    max-height: 828px;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    @include mq-min(md) {
      height: 41.5104vw;
      max-height: 797px;
    }
  }
}

.c-fix-bg--company {
  @extend %c-fix-bg;
  &::before {
    background-image: url(../images/company/mv_sp.jpg);
    @include mq-min(md) {
      background-image: url(../images/company/mv_pc.jpg);
    }
  }
}

.c-fix-bg--contact {
  @extend %c-fix-bg;
  &::before {
    background-image: url(../images/contact/mv_sp.jpg);
    @include mq-min(md) {
      background-image: url(../images/contact/mv_pc.jpg);
    }
  }
}

.c-fix-bg--404 {
  @extend %c-fix-bg;
  &::before {
    background-image: url(../images/404/mv_sp.jpg);
    @include mq-min(md) {
      background-image: url(../images/404/mv_pc.jpg);
    }
  }
}

.c-fix-bg--faq {
  @extend %c-fix-bg;
  &::before {
    background-image: url(../images/faq/mv_sp.jpg);
    @include mq-min(md) {
      background-image: url(../images/faq/mv_pc.jpg);
    }
  }
}

.c-fix-bg--access {
  @extend %c-fix-bg;
  &::before {
    background-image: url(../images/access/mv_sp.jpg);
    @include mq-min(md) {
      background-image: url(../images/access/mv_pc.jpg);
    }
  }
}

.c-fix-bg--rooms {
  @extend %c-fix-bg;
  &::before {
    background-image: url(../images/rooms/mv_sp.jpg);
    @include mq-min(md) {
      background-image: url(../images/rooms/mv_pc.jpg);
    }
  }
}

.c-fix-bg--facility {
  @extend %c-fix-bg;
  &::before {
    background-image: url(../images/facility/mv_sp.jpg);
    @include mq-min(md) {
      background-image: url(../images/facility/mv_pc.jpg);
    }
  }
}

.c-fix-bg--sightseeing {
  @extend %c-fix-bg;
  &::before {
    background-image: url(../images/sightseeing/mv_sp.jpg);
    @include mq-min(md) {
      background-image: url(../images/sightseeing/mv_pc.jpg);
    }
  }
}

.c-fix-bg--info {
  @extend %c-fix-bg;
  &::before {
    background-image: url(../images/info/mv_sp.jpg);
    @include mq-min(md) {
      background-image: url(../images/info/mv_pc.jpg);
    }
  }
}

.c-fix-bg--404 {
  position: relative;
  height: 49.6vw;
  max-height: 372px;

  @include mq-min(md) {
    height: 26.354vw;
    max-height: 506px;
  }

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 67vw;
    max-height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    @include mq-min(md) {
      height: 26.354vw;
      max-height: 506px;
    }
  }
}