@use '../setting/index' as *;

.js-mask {
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: $accent_color;
    transform-origin: right;
    z-index: 5;
  }
}

.js-sub-gallery-mask > * {
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: $accent_color;
    transform-origin: right;
    z-index: 5;
  }
}

.js-mask.is-mask-out {
  &::before {
    animation: mask-out .6s cubic-bezier(0.565, 0.43, 0.24, 0.92) 0s forwards;
  }
}

.js-slide-in .js-mask.is-mask-out {
  &::before {
    animation: mask-out .6s cubic-bezier(0.565, 0.43, 0.24, 0.92) .9s forwards;
  }
}
.js-slide-in .js-sub-gallery-mask.is-mask-out > * {
  &::before {
    animation: mask-out .6s cubic-bezier(0.565, 0.43, 0.24, 0.92) .9s forwards;
  }
}

.js-mask img {
  transform: scale3d(1.15, 1.15, 1);
}

.js-sub-gallery-mask img {
  transform: scale3d(1.15, 1.15, 1);
}

.js-mask.is-mask-out img {
  animation: image-scale .6s cubic-bezier(0.34, 0.615, 0.4, 0.985) .3s forwards;
}

.js-slide-in .js-mask.is-mask-out img {
  animation: image-scale .6s cubic-bezier(0.34, 0.615, 0.4, 0.985) 1.3s forwards;
}

.js-slide-in .js-sub-gallery-mask.is-mask-out img {
  animation: image-scale .6s cubic-bezier(0.34, 0.615, 0.4, 0.985) 1.3s forwards;
}

@keyframes mask-out {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes image-scale {
  0% {
    transform: scale3d(1.15, 1.15, 1);
  }
  100% {
    transform: scale(1);
  }
}