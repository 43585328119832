@use '../setting/index' as *;

.l-footer {
  position: relative;
  padding: 30px 0 90px;
  background: url(../images/common/footer_bg_sp.jpg) no-repeat center -10vw / cover;
  z-index: 2;
  @include mq-min(sm) {
    background: url(../images/common/footer_bg_sp.jpg) no-repeat center -60vw / cover;
  }
  @include mq-min(md) {
    padding: 80px 0;
    background: url(../images/common/footer_bg_pc.jpg) no-repeat center / cover;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color: $accent_color, $alpha: .3);
    z-index: 1;
    mix-blend-mode: multiply;
  }
}

.l-footer__inner {
  position: relative;
  padding: 0 30px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.l-footer__logo {
  display: block;
  width: min(550px, 73.333vw);
  margin: auto;
  @include mq-min(md) {
    width: 415px;
  }
}

.l-footer__address {
  font-size: 1.4rem;
  font-weight: 500;
  color: #FAF7F2;
  line-height: 2;
  margin-top: 30px;
  text-align: center;

  @include mq-min(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    margin-top: 35px;
    line-height: 1.5;
  }
}

.l-footer__address li + li {
  @include mq-min(md) {
    margin-left: 1.2em;
  }
}

.l-footer__tel-fax {
  font-size: 1.4rem;
  font-weight: 500;
  color: #FAF7F2;
  line-height: 2;
  margin-top: 22px;

  @include mq-min(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
}

.l-footer__tel-fax li {
  letter-spacing: 0.2em;
}

.l-footer__tel-fax li + li {
  @include mq-min(md) {
    position: relative;
    margin-left: 1.5em;

    &::before {
      content: '/';
      position: absolute;
      top: -1px;
      left: -0.95em;
      font-size: 1.6rem;
    }
  }
}

.l-footer__text {
  font-size: 1.2rem;
  font-weight: 500;
  color: #FAF7F2;
  letter-spacing: 0.2em;
  margin-top: 22px;
  text-align: center;
  line-height: 2;
  @include mq-min(md) {
    font-size: 1.4rem;
    margin-top: 0;
  }
}

.l-footer__copy {
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  margin-top: 22px;
  @include mq-min(md) {
    font-size: 1.4rem;
    margin-top: 80px;
  }
}