@use '../setting/index' as *;

.p-404-section {
  position: relative;
  padding: 30px 0 50px;
  @include mq-min(md) {
    padding: 60px 0 100px;
  }
}

.p-404-heading {
  font-size: 3rem;
  @include ten-mincho;
  text-align: center;
  line-height: 1.5;
  padding: 38px 1em;
  background: url(../images/common/heading_lower_bg_sp.jpg) no-repeat center / cover;
  @include mq-min(md) {
    font-size: 5rem;
    padding: 33px 1em 32px;
    background: url(../images/common/heading_lower_bg_pc.jpg) no-repeat center / cover;
  }
}

.p-404-heading span {
  display: block;
  width: fit-content;
  padding: 0 7px 5px;
  text-align: center;
  font-size: 2.5rem;
  @include trajan-normal;
  border-bottom: 1px solid $border_color;
  margin: 0 auto 5px;
  @include mq-min(md) {
    display: inline-block;
    font-size: 4rem;
    line-height: 1.1;
    vertical-align: -0.16em;
    border-bottom: none;
    border-right: 1px solid $border_color;
    padding: 0 20px 0 0;
    margin: 0 20px 0 0;
  }
  @include mq-min(lg) {
    font-size: 5rem;
  }
}

.p-404-text {
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;
  @include mq-min(md) {
    font-size: 1.8rem;
    line-height: 2;
    letter-spacing: 0.1em;
  }
}

.p-404-button-wrap {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  @include mq-min(md) {
    width: 232px;
    margin: 60px auto 0;
  }
}