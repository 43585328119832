@use '../setting/index' as *;
@import '~jquery-ui/themes/base/all.css';


.ui-datepicker-trigger {
  display: none;
}
.ui-widget {
  font-size: 1.6rem;
}
.ui-datepicker-calendar .day-sunday > a,
.ui-datepicker-calendar .day-holiday > a {
  background: #fde;
}
.ui-datepicker-calendar .day-saturday > a {
  background: #dff;
}
.ui-icon,
.ui-widget-content .ui-icon {
	background-image: url(../images/top/ui-icons_444444_256x240.png);
}
.ui-widget-header .ui-icon {
	background-image: url(../images/top/ui-icons_444444_256x240.png);
}