@use '../setting/index' as *;

.l-link {
  padding: 17px 1.5em;
  background-color: #EBE6DE;
  mix-blend-mode: multiply;
  @include mq-min(md) {
    padding: 18px;
  }
}

.l-link__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $accent_color;
  font-size: 1.2rem;
  @include ten-mincho;
  line-height: 1.2;
  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

.l-link__inner li {
  border-left: 1px solid $accent_color;
  &:last-of-type {
    border-right: 1px solid $accent_color;
  }
}

.l-link__item {
  padding: 0 9px;
  @include mq-min(md) {
    padding: 0 20px;
    transition: opacity .3s;
  }
  &:hover {
    @include mq-min(md) {
      opacity: .7;
    }
  }
}