@use '../setting/index' as *;

.p-form-wrap {
  position: relative;
  color: #491F00;
  padding: 20px 0 12px;
  background: url(../images/top/form_bg_sp.jpg) no-repeat center / cover;
  z-index: 2;
  @include mq-min(md) {
    padding: 20px 0;
    background: url(../images/top/form_bg_pc.jpg) no-repeat center / cover;
  }
}

.p-form-wrap__inner {
  position: relative;
  padding: 0 30px;
  @include mq-min(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    flex-wrap: wrap;
    max-width: 1280px;
    padding: 0 40px;
    margin: auto;
  }
}

.p-form-tab {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;

  @include mq-min(md) {
    justify-content: flex-start;
    margin-right: 24px;
  }
}

.p-form-tab__item {
  width: 39.333vw;
  padding: 0 1em 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  transition: background-color .3s;

  @include mq-min(md) {
    width: 150px;
    cursor: pointer;
    transition: background-color .3s;
  }

  &:hover {
    @include mq-min(md) {
      background-color: #DBD6CC;
    }
  }
}

.p-form-tab__item.is-current {
  background-color: #DBD6CC;
  pointer-events: none;
}

%p-form-tab__icon {
  display: block;
  margin: auto;
}

.p-form-tab__icon--reserve {
  @extend %p-form-tab__icon;
  width: 23.696%;
  margin-top: 8px;

  @include mq-min(md) {
    width: 40px;
  }
}

.p-form-tab__icon--air {
  @extend %p-form-tab__icon;
  width: 33.848%;
  margin-top: 6px;

  @include mq-min(md) {
    width: 57px;
  }
}

.p-form-tab__heading {
  width: fit-content;
  font-size: 1.4rem;
  @include ten-mincho;
  text-align: center;
  letter-spacing: 0;
  margin: 5px auto 0;

  @include mq-min(md) {
    margin-top: 0;
  }
}

.p-form-tab__heading span {
  display: block;
  font-size: 1.2rem;
  border-bottom: 1px solid $border_color;
  @include trajan-normal;
  letter-spacing: 0.06em;
  padding-bottom: 2px;
  margin-bottom: 2px;

  @include mq-min(md) {
    font-size: 1.2rem;
    letter-spacing: 0.15em;
  }
}

.p-form__inner {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 20px;

  @include mq-min(md) {
    grid-template-columns: 171px 171px 202px;
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 20px;
    margin-top: 0;
  }

  @include mq-min(lg) {
    grid-template-columns: 171px 171px 104px 104px 104px 202px;
    grid-template-rows: 1fr;
  }
}

.p-form {
  display: none;
}

.p-form.is-current {
  display: grid;
}

.p-form__label {
  position: relative;
  font-size: 1.3rem;
  @include ten-mincho;
  @include mq-min(md) {
    font-size: 1.4rem;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 6px 0 6px;
    border-color: $border_color transparent transparent transparent;
    @include mq-min(md) {
      bottom: -15px;
    }
  }
}

.p-form__checkin {
  grid-area: 1 / 1 / 2 / 4;
  @include mq-min(md) {
    grid-area: 1 / 1 / 2 / 2; 
    padding: 0 8px 0 10px;
    border-left: 1px solid $border_color;
  }
  @include mq-min(lg) {
    grid-area: auto;
  }
}

.p-form__checkin input {
  width: max(132px, 90%);
  font-size: 1.6rem;
  padding-right: 29px;
  border-bottom: 1px solid $border_color;
  @include mq-min(md) {
    width: 100%;
    font-size: 2rem;
    padding-right: 20px;
    margin-top: 30px;
    border-bottom: none;
  }
}

.p-form__checkout {
  grid-area: 1 / 4 / 2 / 7;
  @include mq-min(md) {
    grid-area: 1 / 2 / 2 / 3;
    padding: 0 8px 0 10px;
    border-left: 1px solid $border_color;
  }
  @include mq-min(lg) {
    grid-area: auto;
  }
}

.p-form__checkout input {
  width: max(132px, 90%);
  font-size: 1.6rem;
  padding-right: 29px;
  border-bottom: 1px solid $border_color;
  @include mq-min(md) {
    width: 152px;
    font-size: 2rem;
    margin-top: 30px;
    padding-right: 20px;
    border-bottom: none;
  }
}

.p-form__room {
  position: relative;
  width: max(75px, 90%);
  margin-top: 12px;
  grid-area: 2 / 1 / 3 / 3;
  @include mq-min(md) {
    width: 80px;
    padding: 0 9px 0 10px;
    margin-top: 0;
    border-left: 1px solid $border_color;
    grid-area: 1 / 3 / 2 / 4;
  }
  @include mq-min(lg) {
    grid-area: auto;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $border_color;
    @include mq-min(md) {
      content: none;
    }
  }
}

.p-form__room .p-form__label::after {
  @include mq-min(md) {
    bottom: -15px;
  }
}

.p-form__room select {
  position: relative;
  top: 1px;
  width: 36px;
  font-size: 1.6rem;
  text-align: right;

  @include mq-min(md) {
    width: 20px;
    font-size: 2rem;
    text-align: center;
    margin-top: 28px;
  }
}

.p-form__room span {
  font-size: 1.4rem;
  padding-right: 15px;
  vertical-align: bottom;

  @include mq-min(md) {
    display: inline-block;
    margin-bottom: 2px;
  }
}

.p-form__adult {
  position: relative;
  width: max(83px, 90%);
  margin-top: 12px;
  grid-area: 2 / 3 / 3 / 5;
  @include mq-min(md) {
    width: 82px;
    padding: 0 9px 0 10px;
    margin-top: 0;
    border-left: 1px solid $border_color;
    grid-area: 2 / 1 / 3 / 2;
  }
  @include mq-min(lg) {
    grid-area: auto;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $border_color;
    @include mq-min(md) {
      content: none;
    }
  }
}

.p-form__adult .p-form__label::after {
  @include mq-min(md) {
    bottom: -15px;
  }
}

.p-form__adult select {
  position: relative;
  top: 1px;
  width: 36px;
  font-size: 1.6rem;
  text-align: right;

  @include mq-min(md) {
    width: 22px;
    font-size: 2rem;
    text-align: center;
    margin-top: 28px;
  }
}

.p-form__adult span {
  font-size: 1.4rem;
  padding-right: 15px;
  vertical-align: bottom;

  @include mq-min(md) {
    display: inline-block;
    margin-bottom: 2px;
  }
}

.p-form__child {
  width: max(100px, 90%);
  margin-top: 12px;
  border-bottom: 1px solid $border_color;
  grid-area: 2 / 5 / 3 / 7;
  @include mq-min(md) {
    width: 112px;
    margin-top: 0;
    padding: 0 19px 0 10px;
    border-bottom: none;
    border-left: 1px solid $border_color;
    grid-area: 2 / 2 / 3 / 3;
  }
  @include mq-min(lg) {
    grid-area: auto;
  }
}

.p-form__child .p-form__label::after {
  @include mq-min(md) {
    bottom: -15px;
  }
}

.p-form__child-input {
  position: relative;
  top: 1px;
  width: 36px;
  font-size: 1.6rem;
  text-align: right;

  @include mq-min(md) {
    width: 18px;
    font-size: 2rem;
    text-align: center;
    margin-top: 28px;
  }
}

.p-form__child span {
  font-size: 1.4rem;
  padding-right: 15px;
  vertical-align: bottom;

  @include mq-min(md) {
    display: inline-block;
    margin-bottom: 2px;
  }
}

.p-form__dropdown-menu {
  display: none;
  z-index: 10;
  position: absolute;
  bottom: -98px;
  left: 50%;
  transform: translateX(-50%);
  width: 96vw;
  max-width: 360px;
  padding: 10px;
  border: 1px solid $border_color;
  background: url(../images/top/form_bg_sp.jpg) no-repeat center / cover;
  @include mq-min(md) {
    max-width: initial;
    width: fit-content;
    bottom: -112px;
  }
}

.p-form__dropdown-menu.is-open {
  display: block;
  @include mq-min(md) {
    display: flex;
  }
}

.p-form__dropdown-menu-item + .p-form__dropdown-menu-item {
  margin-top: 10px;
  @include mq-min(md) {
    margin-top: 0;
    margin-left: 10px;
  }
}

.p-form__dropdown-label {
  font-size: 1.4rem;
  @include ten-mincho;
  text-align: center;
  border-bottom: 1px solid $border_color;
  background-color: #fff;
  padding: 0.1em 0.5em;

}

.p-form__dropdown-flex {
  display: flex;
}

.p-form__select {
  flex: 1;
  text-align: center;
  @include mq-min(md) {
    flex: initial;
    width: 120px;
  }
}

.p-form__doropdown-label--icon {
  font-size: 1.4rem;
  @include ten-mincho;
  text-align: center;
}

.p-form__select select {
  font-size: 1.6rem;
  @include ten-mincho;
  width: 50%;
  margin: 6px 6px 0;
  padding: 0 3px;
  border-bottom: 1px solid $border_color;
}

.p-form__links {
  margin-top: 20px;
  grid-area: 3 / 1 / 4 / 7;
  @include mq-min(md) {
    margin-top: 0;
    grid-area: 2 / 3 / 3 / 4;
  }
  @include mq-min(lg) {
    grid-area: auto;
  }
}

.p-form__button {
  display: grid;
  place-content: center;
  width: 100%;
  font-size: 1.6rem;
  background-color: #D4CEC5;
  padding: 8px 1em;
  border-radius: 5px;
  @include ten-mincho;

  @include mq-min(md) {
    width: 202px;
    font-size: 2rem;
    padding: 12px 1em;
    border-radius: 0;
    transition: background-color .3s, color .3s;
  }

  &:hover {
    @include mq-min(md) {
      background-color: $accent_color;
      color: #fff;
    }
  }
}

.p-form__button--airline {
  display: grid;
  place-content: center;
  width: 100%;
  font-size: 1.6rem;
  background-color: #D4CEC5;
  padding: 9px 1em 7px;
  border-radius: 5px;
  letter-spacing: 0;
  @include ten-mincho;

  @include mq-min(md) {
    width: 198px;
    font-size: 2rem;
    padding: 12px 1em;
    border-radius: 0;
    transition: color .3s, background-color .3s;
  }

  &:hover {
    @include mq-min(md) {
      color: #fff;
      background-color: $accent_color;
    }
  }
}

.p-form__link-text {
  font-size: 1.2rem;
  margin-top: 15px;
  text-decoration: underline;
  @include ten-mincho;

  @include mq-min(md) {
    font-size: 1.4rem;
    margin-top: 5px;
    transition: opacity .3s;
  }

  &:hover {
    @include mq-min(md) {
      opacity: .7;
    }
  }
}

.p-form--secondary {
  display: none;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding-bottom: 38px;
  @include mq-min(md) {
    grid-template-columns: 296px 240px 122px 198px;
    grid-template-rows: 1fr;
    padding-bottom: 0;
  }
}

.p-form--secondary.is-current {
  display: grid;
}

.p-form__airline {
  grid-area: 1 / 1 / 2 / 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 3px;
  border-bottom: 1px solid $border_color;

  @include mq-min(md) {
    grid-area: 1 / 1 / 2 / 2;
    display: block;
    margin-top: 0;
    padding: 0 18px 0 20px;
    border-bottom: none;
    border-left: 1px solid $border_color;
  }
}

.p-form__airline label {
  font-size: 1.4rem;
  @include ten-mincho;
  letter-spacing: 0;
}

.p-form__airline-select-wrap {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 6px 0 6px;
    border-color: $border_color transparent transparent transparent;
  }

  @include mq-min(md) {
    margin-top: 27px;
  }
}

.p-form__airline select {
  font-size: 1.6rem;
  @include ten-mincho;

  @include mq-min(md) {
    font-size: 2rem;
  }
}

.p-form__date--airline {
  grid-area: 2 / 1 / 3 / 2;
  width: 90%;
  margin-top: 21px;
  padding-bottom: 3px;
  border-bottom: 1px solid $border_color;
  @include mq-min(md) {
    grid-area: 1 / 2 / 2 / 3;
    width: 238px;
    padding: 0 18px 0 20px;
    margin-top: 0;
    border-bottom: none;
    border-left: 1px solid $border_color;
  }
}

.p-form__date--airline label {
  letter-spacing: 0.07em;
}

.p-form__select--airline {
  grid-area: 2 / 2 / 3 / 3;
  width: 90%;
  margin-top: 21px;
  margin-left: auto;
  border-bottom: 1px solid $border_color;
  @include mq-min(md) {
    grid-area: 1 / 3 / 2 / 4;
    width: 120px;
    padding: 0 19px 0 20px;
    margin-top: 0;
    border-bottom: none;
    border-left: 1px solid $border_color;
  }
}

.p-form__button--airline {
  grid-area: 3 / 1 / 4 / 3;
  margin-top: 20px;
  @include mq-min(md) {
    grid-area: 1 / 4 / 2 / 5;
    width: 198px;
    margin-top: 0;
  }
}

.p-form__calendar--airline {
  width: 130px;
  font-size: 1.6rem;
  @include ten-mincho;

  @include mq-min(md) {
    font-size: 2rem;
    margin-top: 27px;
  }
}

.p-form__date--airline .p-form__label::after {
  @include mq-min(md) {
    bottom: -9px;
    right: -78px;
  }
}

.p-form__select--airline .p-form__label::after {
  @include mq-min(md) {
    bottom: -11px;
  }
}

.p-form__select--airline select {
  width: 42px;
  font-size: 1.6rem;
  text-align: center;
  @include ten-mincho;
  margin-left: 45px;
  @include mq-min(md) {
    width: 38px;
    font-size: 2rem;
    text-align: center;
    margin-top: 24px;
    margin-left: 0;
  }
}

.p-form__select--airline-unit {
  position: relative;
  top: 3px;
  left: 3px;
  font-size: 1.4rem;
  padding-right: 1.5em;

  @include mq-min(md) {
    top: 16px;
  }
}