@use '../setting/index' as *;

.p-facility-section {
  padding: 80px 0 50px;
  @include mq-min(md) {
    padding: 200px 0 100px;
  }
}

.p-facility-section--pb-large {
  padding: 80px 0 100px;
  @include mq-min(md) {
    padding: 200px 0 100px;
  }
}

.p-facility-grid {
  position: relative;
  transform: translateY(60px);
  transition: transform .7s .5s, opacity .7s .5s;
  opacity: 0;
  scroll-margin-top: 80px;
  @include mq-min(md) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 0.3fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    align-items: end;
    scroll-margin-top: 200px;
  }
  @include mq-min(lg) {
    grid-template-columns: 340px 1fr;
    grid-template-rows: 1fr 362px;
    grid-column-gap: 50px;
    grid-row-gap: 10px;
  }
}
.is-show .p-facility-grid {
  transform: translateY(0);
  opacity: 1;
}

.p-facility-grid .p-facility-grid__heading-set {
  @include mq-min(md) {
    grid-area: 1 / 1 / 2 / 3;
  }
  @include mq-min(lg) {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.p-facility-grid .p-facility-gallery__main {
  @include mq-min(md) {
    grid-area: 2 / 2 / 3 / 3;
  }
  @include mq-min(lg) {
    grid-area: 1 / 2 / 4 / 3;
  }
}

.p-facility-grid .p-facility-gallery__sub {
  @include mq-min(md) {
    grid-area: 2 / 1 / 3 / 2;
  }
  @include mq-min(lg) {
    grid-area: 2 / 1 / 4 / 2;
  }
}

.p-facility-grid--reverse {
  position: relative;
  transform: translateY(60px);
  transition: transform .7s .5s, opacity .7s .5s;
  opacity: 0;
  scroll-margin-top: 80px;
  @include mq-min(md) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 0.3fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    align-items: end;
    scroll-margin-top: 200px;
  }
  @include mq-min(lg) {
    grid-template-columns: 1fr 340px;
    grid-template-rows: 1fr 362px;
    grid-column-gap: 52px;
  }
}
.is-show .p-facility-grid--reverse {
  transform: translateY(0);
  opacity: 1;
}

.p-facility-grid--reverse .p-facility-grid__heading-set {
  @include mq-min(md) {
    grid-area: 1 / 1 / 2 / 3;
  }
  @include mq-min(lg) {
    grid-area: 1 / 2 / 2 / 3;
  }
}

.p-facility-grid--reverse .p-facility-gallery__main {
  @include mq-min(md) {
    grid-area: 2 / 2 / 3 / 3;
  }
  @include mq-min(lg) {
    grid-area: 1 / 1 / 3 / 2;
  }
}

.p-facility-grid--reverse .p-facility-gallery__sub {
  @include mq-min(md) {
    grid-area: 2 / 1 / 3 / 2;
  }
  @include mq-min(lg) {
    grid-area: 2 / 2 / 3 / 3;
  }
}

.p-facility-heading {
  position: relative;
  width: fit-content;
  font-size: 1.3rem;
  @include ten-mincho;
  @include mq-min(md) {
    font-size: 1.8rem;
  }
}

.p-facility-heading::before {
  content: '';
  position: absolute;
  top: 57%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: $border_color;
  @include mq-min(md) {
    top: 58%;
  }
}

.p-facility-heading__en {
  display: block;
  font-size: 1.9rem;
  @include trajan-normal;
  @include mq-min(md) {
    font-size: 4rem;
    padding-bottom: 6px;
  }
}

.p-facility-heading--underline {
  position: relative;
  width: fit-content;
  font-size: 1.3rem;
  @include ten-mincho;
  @include mq-min(md) {
    font-size: 1.8rem;
  }
}

.p-facility-heading--underline span {
  display: block;
  font-size: 1.9rem;
  @include trajan-normal;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
  @include mq-min(md) {
    font-size: 4rem;
    padding-bottom: 6px;
    text-underline-offset: 6px;
  }
}

.p-facility-text {
  color: #333;
  font-size: 1.3rem;
  margin-top: 20px;
  letter-spacing: 0;
  line-height: 2;
  @include mq-min(md) {
    font-size: 1.6rem;
    margin-top: 16px;
    letter-spacing: 0.1em;
    line-height: 1.5;
  }
}

.p-facility-gallery {
  margin-top: 20px;
  @include mq-min(md) {
    display: contents;
  }
}

.p-facility-gallery__main {
  position: relative;
  padding-right: 10px;
  @include mq-min(md) {
    padding-right: 0;
    max-width: 800px;
  }
}

.p-facility-gallery__main::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 10px);
  height: 100%;
  background-color: #DBD6CC;
  display: block;
  mix-blend-mode: multiply;
  z-index: -1;
  @include mq-min(md) {
    width: 100%;
    height: 100%;
  }
}

.p-facility-gallery__main img {
  border: 1px solid #fff;
  image-rendering: -webkit-optimize-contrast;
  @include mq-min(md) {
    border-width: 3px;
  }
}

.p-facility-gallery__sub {
  display: flex;
  column-gap: 5px;
  margin-top: 60px;

  @include mq-min(md) {
    flex-direction: column;
    row-gap: 10px;
    column-gap: 0;
    margin-top: 0;
  }
}
.p-facility-gallery__sub-image {
  @include mq-min(md) {
    transition: opacity .3s;
  }
}
.p-facility-gallery__sub-image:hover {
  @include mq-min(md) {
    opacity: 0.7;
    cursor: pointer;
  }
}

.p-facility-gallery__sub-image img {
  image-rendering: -webkit-optimize-contrast;
  @include mq-min(md) {
    width: 330px;
    height: 176px;
    object-fit: cover;
  }
}

.p-facility-section--bg {
  padding: 30px 0 50px;
  background: url(../images/common/section_bg_sp.jpg) repeat center / auto;
  @include mq-min(md) {
    padding: 100px 0;
    margin-top: 100px;
    background: url(../images/common/section_bg_pc.jpg) repeat-y center / auto;
  }
}

.p-facility-card-wrap {
  display: contents;
  @include mq-min(md) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 60px;
    row-gap: 100px;
  }
}

.p-facility-card {
  position: relative;
  scroll-margin-top: 20px;
  @include mq-min(md) {
    width: calc((100% - 60px) / 2);
    display: flex;
    flex-direction: column;
    scroll-margin-top: 160px;
  }
}

.p-facility-card + .p-facility-card {
  margin-top: 100px;
  @include mq-min(md) {
    margin-top: 0;
  }
}

.p-facility-card__text {
  color: #333;
  font-size: 1.3rem;
  line-height: 2;
  margin-top: 20px;
  z-index: 2;
  @include mq-min(md) {
    font-size: 1.6rem;
    margin-top: 16px;
    margin-bottom: 30px;
    line-height: 1.5;
  }
}

.p-facility-card__image {
  display: block;
  margin-top: 20px;
  z-index: 2;
  @include mq-min(md) {
    margin-top: auto;
  }
}

.p-facility-card__image img {
  position: relative;
  z-index: 3;
}

// 背景の設定
.p-facility-bg-palm::before {
  content: '';
  position: absolute;
  width: min(702px, 93.6vw);
  height: min(550px, 73.333vw);
  top: -94px;
  right: -151px;
  background: url(../images/common/bg_palm_sp.png) no-repeat center/cover;
  mix-blend-mode: multiply;
  transform: rotate(-8deg);
  z-index: -1;
  @include mq-min(md) {
    width: 1005px;
    height: 788px;
    top: 30px;
    right: -820px;
    background: url(../images/common/bg_palm_pc.png) no-repeat center/cover;
    transform: none;
  }
}

.p-facility-bg-square01::after {
  content: '';
  position: absolute;
  width: min(500px, 66.666vw);
  height: min(855px, 114vw);
  top: -30px;
  right: -45px;
  background-color: #EBE6DE;
  mix-blend-mode: multiply;
  z-index: -2;
  transform: translateX(100%);
  opacity: 0;
  transition: transform .6s cubic-bezier(0.565, 0.43, 0.24, 0.92), opacity .6s;
  @include mq-min(md) {
    width: 774px;
    height: 750px;
    top: -80px;
    right: -360px;
  }
}

.is-show.p-facility-bg-square01::after {
  transform: translateX(0);
  opacity: 1;
}

.p-facility-bg-strelitzia::before {
  content: '';
  position: absolute;
  width: min(500px, 66.666vw);
  height: min(718px, 95.733vw);
  top: -145px;
  left: -138px;
  background: url(../images/common/bg_strelitzia_sp.png) no-repeat center/cover;
  mix-blend-mode: multiply;
  z-index: -1;
  @include mq-min(md) {
    width: 808px;
    height: 1161px;
    top: -544px;
    left: -600px;
    background: url(../images/common/bg_strelitzia_pc.png) no-repeat center/cover;
  }
}

.p-facility-bg-square02::after {
  content: '';
  position: absolute;
  width: min(500px, 66.666vw);
  height: min(814px, 108vw);
  top: -30px;
  left: -45px;
  background-color: #DBD6CC;
  z-index: -2;
  transform: translateX(-100%);
  opacity: 0;
  transition: transform .6s cubic-bezier(0.565, 0.43, 0.24, 0.92), opacity .6s;
  @include mq-min(md) {
    width: 774px;
    height: 750px;
    top: -90px;
    left: -360px;
  }
}
.is-show.p-facility-bg-square02::after {
  transform: translateX(0);
  opacity: 1;
}

.p-facility-bg-monstera::before {
  content: '';
  position: absolute;
  width: min(400px, 53.33vw);
  height: min(470px, 62.66vw);
  top: -97px;
  right: -84px;
  background: url(../images/common/bg_monstera_sp.png) no-repeat center/cover;
  mix-blend-mode: multiply;
  z-index: -1;
  @include mq-min(md) {
    width: 600px;
    height: 704px;
    top: -370px;
    right: -440px;
    background: url(../images/common/bg_monstera_pc.png) no-repeat center/cover;
  }
}

.p-facility-bg-square03::after {
  content: '';
  position: absolute;
  width: min(500px, 66.666vw);
  height: min(810px, 108vw);
  top: -30px;
  right: -45px;
  background-color: rgba($color: #491F00, $alpha: .5);
  mix-blend-mode: multiply;
  z-index: -2;
  transform: translateX(100%);
  opacity: 0;
  transition: transform .6s cubic-bezier(0.565, 0.43, 0.24, 0.92), opacity .6s;
  @include mq-min(md) {
    width: 774px;
    height: 750px;
    top: -60px;
    right: -360px;
  }
}
.is-show.p-facility-bg-square03::after {
  transform: translateX(0);
  opacity: 1;
}

.p-facility-bg-palm--reverse::before {
  content: '';
  position: absolute;
  top: -148px;
  left: -200px;
  width: min(702px, 93.36vw);
  height: min(550px, 73.333vw);
  background: url(../images/common/bg_palm_sp.png) no-repeat center/cover;
  mix-blend-mode: multiply;
  transform: scale(-1, 1);
  z-index: 1;
  @include mq-min(md) {
    width: 1006px;
    height: 788px;
    top: -474px;
    left: -820px;
    background: url(../images/common/bg_palm_pc.png) no-repeat center/cover;
  }
}

.p-facility-bg-hibiscus::before {
  content: '';
  position: absolute;
  top: -85px;
  right: -108px;
  width: min(460px, 61.333vw);
  height: min(464px, 61.866vw);
  background: url(../images/common/bg_hibiscus_sp.png) no-repeat center/cover;
  mix-blend-mode: multiply;
  transform: scale(-1, 1);
  z-index: 1;
  @include mq-min(md) {
    width: 658px;
    height: 662px;
    top: -300px;
    right: -1100px;
    background: url(../images/common/bg_hibiscus_pc.png) no-repeat center/cover;
  }
}

.p-facility-bg-monstera--reverse::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: 28px;
    left: -460px;
    width: 600px;
    height: 704px;
    background: url(../images/common/bg_monstera_pc.png) no-repeat center/cover;
    mix-blend-mode: multiply;
    transform: scale(-1, 1);
  }
}