@use '../setting/index' as *;

.c-inner {
  position: relative;
  padding: 0 45px;
  @include mq-min(md) {
    width: min(1280px, 100%);
    padding: 0 40px;
    margin: auto;
  }
}

.c-inner--sp-wide {
  position: relative;
  padding: 0 30px;
  @include mq-min(md) {
    width: min(1280px, 100%);
    padding: 0 40px;
    margin: auto;
  }
}