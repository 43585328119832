@use '../setting/index' as *;

%heading-lower-page {
  font-size: 1.4rem;
  @include ten-mincho;
  text-align: center;
  line-height: 1;
  padding: 23px 1em 27px;
  background: url(../images/common/heading_lower_bg_sp.jpg) no-repeat center / cover;
  @include mq-min(md) {
    font-size: 2.2rem;
    padding: 39px 1em;
    background: url(../images/common/heading_lower_bg_pc.jpg) no-repeat center / cover;
  }
}

%heading-lower-page-span {
  display: block;
  width: fit-content;
  text-align: center;
  font-size: 2.5rem;
  @include trajan-normal;
  border-bottom: 1px solid $border_color;
  margin: 0 auto 8px;
  letter-spacing: 0.05em;
  @include mq-min(md) {
    display: inline-block;
    font-size: 4rem;
    line-height: 1.1;
    letter-spacing: 0.1em;
    vertical-align: -0.16em;
    border-bottom: none;
    border-right: 1px solid $border_color;
    margin: 0 20px 0 0;
  }
  @include mq-min(lg) {
    font-size: 5rem;
  }
}

.c-heading-lower-page {
  @extend %heading-lower-page;
}

.c-heading-lower-page span {
  @extend %heading-lower-page-span;
  padding: 0 7px 8px;
  @include mq-min(md) {
    padding: 0 20px 0 0;
  }
}

.c-heading-lower-page--faq {
  @extend %heading-lower-page;
}

.c-heading-lower-page--faq span {
  @extend %heading-lower-page-span;
  padding: 0 25px 8px;
  @include mq-min(md) {
    padding: 0 48px 0 0;
  }
}

.c-heading {
  font-size: 1.3rem;
  @include ten-mincho;
  @include mq-min(md) {
    font-size: 1.8rem;
  }
}

.c-heading span {
  display: block;
  width: fit-content;
  font-size: 1.9rem;
  @include trajan-normal;
  border-bottom: 1px solid #000;
  @include mq-min(md) {
    font-size: 4rem;
  }
}

.c-heading-underline {
  @include ten-mincho;
  font-size: 1.9rem;
  text-decoration: underline;
  text-underline-offset: 6px;

  @include mq-min(md) {
    position: relative;
    font-size: 4rem;
    text-decoration: none;
    padding-bottom: 10px;
    border-bottom: 2px solid $border_color;
  }

  &::before {
    @include mq-min(md) {
      content: "";
      position: absolute;
      right: 100%;
      bottom: -2px;
      width: min(360px, 100%);
      height: 2px;
      background-color: $accent_color;
    }
  }
}

.c-heading-underline--sightseeing {
  @include ten-mincho;
  font-size: 1.9rem;

  @include mq-min(md) {
    position: relative;
    font-size: 4rem;
    text-decoration: none;
    padding-bottom: 10px;
    border-bottom: 2px solid $border_color;
  }

  @include mq-min(xl) {
    white-space: nowrap;
  }

  &::before {
    @include mq-min(md) {
      content: "";
      position: absolute;
      right: 100%;
      bottom: -2px;
      width: min(360px, 100%);
      height: 2px;
      background-color: $accent_color;
    }
  }
}

.c-heading-underline--sightseeing span {
  display: inline-block;
  border-bottom: 1px solid $border_color;
  padding: 3px 0;
  @include mq-min(md) {
    display: inline;
    border: none;
    padding-bottom: 0;
  }
}