@use '../setting/index' as *;

.c-button {
  position: relative;
  display: grid;
  place-items: center;
  width: min(530px, 70.666vw);
  min-height: 45px;
  margin: auto;
  font-size: 1.4rem;
  @include ten-mincho;

  @include mq-min(md) {
    width: 100%;
    min-height: 58px;
    font-size: 1.8rem;
    margin: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    border: 1px solid $accent_color;
    z-index: 2;
    @include mq-min(md) {
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      transition: transform .3s;
    }
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    background-color: #D4CEC5;
    z-index: -1;
    @include mq-min(md) {
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      transition: transform .3s;
    }
  }

  &:hover {
    @include mq-min(md) {
      &::before {
        transform: translate(2px,2px);
      }
      &::after {
        transform: translate(-2px,-2px);
      }
    }
  }
}

.c-button span {
  z-index: 3;
}