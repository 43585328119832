@use '../setting/index' as *;

.p-contact-section {
  position: relative;
  padding: 50px 0;
  @include mq-min(md) {
    padding: 100px 0;
  }
}

.p-contact-text-wrap {
  margin-top: 15px;
  @include mq-min(md) {
    margin-top: 41px;
  }
}

.p-contact-text-wrap--thanks-page {
  margin-top: 15px;
  @include mq-min(md) {
    margin-top: 124px;
    padding-bottom: 25px;
  }
}
.p-contact-text-wrap--thanks-page .p-contact-text {
  text-align: center;
}

.p-contact-text {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #333;
  @include mq-min(md) {
    font-size: 1.8rem;
    line-height: 2;
  }
}
.p-contact-text + .p-contact-text {
  margin-top: 20px;
  @include mq-min(md) {
    margin-top: 40px;
  }
}

.p-contact-text--kerning-min {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #333;
  margin-top: 20px;
  @include mq-min(md) {
    font-size: 1.8rem;
    line-height: 2;
    letter-spacing: 0.08em;
    margin-top: 40px;
  }
}


.p-contact-indicator {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 293px;
  margin: 50px auto 0;
  z-index: 2;

  @include mq-min(md) {
    width: min(789px, 100%);
    margin-top: 97px;
  }
}


.p-contact-indicator__item:first-of-type,
.p-contact-indicator__item:nth-of-type(2) {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 27px;
    left: 89px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 8px;
    border-color: transparent transparent transparent $border_color;
    @include mq-min(md) {
      top: 52px;
      left: 225px;
      border-width: 9px 0 9px 16px;
    }
  }
}

.p-contact-indicator__num {
  display: grid;
  place-items: center;
  width: 50px;
  height: 50px;
  @include trajan-normal;
  font-size: 2.5rem;
  line-height: 0;
  letter-spacing: 0;
  background-color: #EBE6DE;
  border: 1px solid $border_color;
  border-radius: 50%;
  margin: auto;
  @include mq-min(md) {
    font-size: 5rem;
    width: 100px;
    height: 100px;
    border-width: 2px;
  }
}

.p-contact-indicator__num.is-current {
  color: #fff;
  background-color: $accent_color;
}
.p-contact-indicator__item:last-of-type .p-contact-indicator__num.is-current {
  background-color: #AA1414;
  border-color: #AA1414;
}

.p-contact-indicator__text {
  font-size: 1.2rem;
  color: #333;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.1em;
  margin-top: 5px;
  @include mq-min(md) {
    font-size: 1.6rem;
    margin-top: 17px;
  }
}

.p-contact-form {
  margin-top: 36px;

  @include mq-min(md) {
    margin-top: 81px;
  }
}

#mw_wp_form_mw-wp-form-28 {
  margin-top: 36px;

  @include mq-min(md) {
    margin-top: 81px;
  }
}

.p-contact-form__content {
  padding: 20px 0;
  border-bottom: 1px solid #000;
  line-height: 1.5;
  @include mq-min(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.p-contact-form__content:last-of-type {
  @include mq-min(md) {
    align-items: flex-start;
  }
}

.p-contact-form__content dt {
  position: relative;
  font-size: 1.2rem;
  font-weight: normal;
  color: #333;
  padding: 0 0 10px 0;
  @include mq-min(md) {
    width: 25.1%;
    font-size: 1.8rem;
    padding: 6px 2em 0 20px;
  }
}

.p-contact-form__content dd {
  font-size: 1.3rem;
  @include mq-min(md) {
    width: 74.9%;
    font-size: 1.8rem;
    padding-left: 8px;
  }
}

.p-contact-form__content dd input,
.p-contact-form__content dd textarea {
  width: 100%;
  padding: 15px 8px 14px 18px;
  background-color: rgba($color: #DBD6CC, $alpha: .2);
  line-height: 1.5;
  mix-blend-mode: multiply;
  @include mq-min(md) {
    padding: 14px 15px 13px;
  }
  &:focus {
    outline: 1px solid;
  }
}

.p-contact-form__content input[type="email"] {
  letter-spacing: 0;
  word-break: break-all;

  @include mq-min(md) {
    letter-spacing: 0.1em;
  }
}

.p-contact-form__content ::placeholder {
  color: #A1A1A1;
}
.p-contact-form__indent {
  padding-left: 18px;
  @include mq-min(md) {
    padding-left: 0;
  }
}

.p-contact-form__button-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto 0;
  @include mq-min(md) {
    width: auto;
    margin: 100px auto 0;
  }
}

.p-contact-form__button-wrap button {
  text-align: center;
  @include mq-min(md) {
    width: 232px;
    height: 58px;
  }
}

.p-contact-form__button-wrap button + button {
  margin-left: 15px;
  @include mq-min(md) {
    margin-top: 0;
    margin-left: 100px;
  }
}

.p-contact-form__button-wrap--conrirm {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto 0;
  @include mq-min(md) {
    width: auto;
    margin: 100px auto 0;
  }
}
.p-contact-form__button-wrap--conrirm button {
  height: 65px;
  @include mq-min(md) {
    width: 232px;
    height: 58px;
  }
}

.p-contact-form__button-wrap--conrirm button + button {
  margin-left: 15px;
  @include mq-min(md) {
    margin-top: 0;
    margin-left: 100px;
  }
}
